import React, { useEffect, useState } from "react";
import { Link, RouteComponentProps, useHistory } from "react-router-dom";
import { Col, Form, notification, Row, Tooltip } from "antd";
import moment from "moment";

import api from "../../../services/apiAuth";

import MonetaryInput2 from "../../../components/MonetaryInput2";
import PageContainer from "../../../containers/PageContainer";

import OrderPaymentType from "../../../models/json/OrderPaymentType.json";

import apiMercury from "../../../services/apiMercury";

import ptBR from "antd/es/date-picker/locale/pt_BR";

import {
  Button,
  Capitalize,
  ChevronIcon,
  ColAnexo,
  ColIcon,
  Container,
  Content,
  ContentFiles,
  DatePicker,
  Footer,
  InfoCircleIcon,
  Input,
  NewRowButton,
  Select,
  Title,
  TrashIcon,
  TrashIconUpload,
  Validate,
} from "./styles";

type Item = {
  key: number;
  payment_amount: number;
  product_id: number;
  payment_method: number;
  payment_date: string;
  additional_value: number;
  discounts: number;
  create_accounts_payments: boolean;
};

interface ComponentProps extends RouteComponentProps<{}, {}, any> {}

const AddNewPayment: React.FC<ComponentProps> = ({ location }) => {
  const { state } = location;
  const { store, employees, month } = state;
  const history = useHistory();

  const [form] = Form.useForm();

  const [fileData, setFileData] = useState<{
    url_file: string;
    s3_key: string;
  } | null>(null);

  const [total, setTotal] = useState({
    totalPaymentAmount: 0,
    additional_value: 0,
    discount: 0,
    totalValue: 0,
  });

  const [items, setItems] = useState<Item[]>([
    {
      key: Date.now(),
      payment_amount: 0,
      product_id: 0,
      payment_method: 0,
      payment_date: "",
      additional_value: 0,
      discounts: 0,
      create_accounts_payments: false,
    },
  ]);

  const currentMonthStart = moment(moment(month))
    .startOf("month")
    .format("01/MM/YYYY");
  const currentMonthEnd = moment(moment(month))
    .endOf("month")
    .add(1, "day")
    .format("DD/MM/YYYY");

  const calculateTotalProductsValue = (_items) => {
    return _items.reduce(
      (acc, item) => {
        const payment_amount = +item.payment_amount || 0;
        const additional_value = +item.additional_value || 0;
        const discounts = +item.discounts || 0;

        acc.totalPaymentAmount += payment_amount;
        acc.additional_value += additional_value;
        acc.discount += discounts;

        acc.totalValue =
          acc.totalPaymentAmount + acc.additional_value - acc.discount;

        return acc;
      },
      {
        totalPaymentAmount: 0,
        additional_value: 0,
        discount: 0,
        totalValue: 0,
      }
    );
  };

  useEffect(() => {
    const totals = calculateTotalProductsValue(items);
    setTotal(totals);
  }, [items]);

  const addItem = () => {
    setItems([
      ...items,
      {
        key: Date.now(),
        payment_amount: 0,
        product_id: 0,
        payment_method: 0,
        payment_date: "",
        additional_value: 0,
        discounts: 0,
        create_accounts_payments: false,
      },
    ]);
  };

  const removeItem = (key) => {
    let _itemList = items.filter((item) => item.key !== key);

    setItems(_itemList);
    _itemList.forEach((item, index) => {
      form.setFieldsValue({
        [`product_id${index}`]: item.product_id || "",
        [`payment_method${index}`]:
          typeof item.payment_method !== "number" ? "" : item.payment_method,
        [`payment_date${index}`]: item.payment_date
          ? moment(item.payment_date)
          : null,
      });
    });
    form.setFieldsValue({
      [`product_id${_itemList.length}`]: null,
      [`payment_method${_itemList.length}`]: null,
      [`payment_date${_itemList.length}`]: null,
    });
  };

  const handleItemChange = (value, name, index) => {
    let _items = [...items];
    _items[index] = { ..._items[index], [name]: value };
    setItems(_items);
  };

  const handleAdditionalValueChange = (index, value) => {
    const newItems = [...items];
    newItems[index].additional_value = +value || 0;
    const totals = calculateTotalProductsValue(newItems);
    setItems(newItems);
    setTotal(totals);
  };

  const handleDiscountChange = (index, value) => {
    const newItems = [...items];
    newItems[index].discounts = +value || 0;
    const totals = calculateTotalProductsValue(newItems);
    setItems(newItems);
    setTotal(totals);
  };

  const handleValueChange = (index, value) => {
    const newItems = [...items];
    newItems[index].payment_amount = +value || 0;

    const totals = calculateTotalProductsValue(newItems);
    setItems(newItems);
    setTotal(totals);
  };

  const handleFileUpload = async () => {
    const inputFile = document.getElementById(
      "input_file"
    ) as HTMLInputElement | null;

    const file = inputFile?.files?.[0];

    if (file) {
      try {
        const timeStamp = new Date().getTime();
        const fileNameWithTimeStamp = `${file.name}_${timeStamp}`;

        const formData = new FormData();
        const renamedFile = new File([file], fileNameWithTimeStamp, {
          type: file.type,
        });
        formData.append("file", renamedFile);
        formData.append("owner", "ti");
        formData.append("folder", "folha");
        formData.append("action", "create");

        const { data } = await api.post("/files-management", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        notification.success({
          message: "Arquivo criado com sucesso",
        });

        const { file_url, file_name } = data.content;
        return { url_file: file_url, s3_key: file_name };
      } catch (error) {
        const errorResponse = (error as any)?.response?.data;
        const errorMessage =
          errorResponse?.message ||
          "Erro inesperado ao fazer upload do arquivo";

        notification.error({
          message: "Erro ao fazer upload do arquivo",
          description: errorMessage,
          duration: 5,
        });

        if (
          errorResponse?.statusCode === 400 &&
          errorMessage.includes(
            "Já existe um arquivo de mesmo nome nesta pasta"
          )
        ) {
          return null;
        }
      }
    } else {
      return {};
    }
  };

  const handleSubmit = async () => {
    await form.validateFields();
    const values = form.getFieldsValue();
    const fileUpload = await handleFileUpload();

    if (fileUpload === null) {
      return;
    }
    const payload = {
      id: 3,
      name: values.name,
      store_id: store,
      total_value: total.totalValue.toFixed(2),
      month_competence: moment(values?.month_competence).toISOString(),
      url_file: fileUpload?.url_file || "",
      s3_key: fileUpload?.s3_key || "",
      observation: values.observation,
      category_id: values.category_id,
      peopleCostItems: items.map((item) => ({
        product_id: item.product_id,
        payment_amount: item.payment_amount,
        payment_method: item.payment_method,
        payment_date: moment(values?.payment_date).toISOString(),
        additional_value: item.additional_value,
        discounts: item.discounts,
        create_accounts_payments: true,
      })),
    };

    try {
      await apiMercury.post(`/people-cost`, payload);

      notification.success({
        message: "Folha de pagamento adicionada com sucesso!",
        duration: 5,
      });

      history.push({
        pathname: "/people",
        state: {
          store_param: store,
          shouldSearch: true,
          data_inicial: currentMonthStart,
          data_final: currentMonthEnd,
        },
      });
    } catch (error) {
      notification.error({
        message: "Oops, parece que ocorreu um erro!",
        duration: 5,
      });
    }
  };

  return (
    <PageContainer
      route="Adicionar Nova Folha de Pagamento"
      content={
        <>
          <Link
            to={{
              pathname: "/people",
              state: {
                store_param: store,
                shouldSearch: true,
                data_inicial: currentMonthStart,
                data_final: currentMonthEnd,
              },
            }}
          >
            <ChevronIcon />
          </Link>
        </>
      }
    >
      <Container>
        <Form layout="vertical" initialValues={{ remember: false }} form={form}>
          <Content>
            <Row gutter={8}>
              <Col md={12} xs={24}>
                <Form.Item
                  label={
                    <span>
                      Nome
                      <Tooltip title="Nome do Funcionário">
                        <InfoCircleIcon />
                      </Tooltip>
                    </span>
                  }
                  name="name"
                  rules={[{ required: true, message: "Campo obrigatório" }]}
                >
                  <Select
                    showSearch
                    placeholder="Digite um nome"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toString()
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    onChange={(value) => form.setFieldsValue({ name: value })}
                  >
                    {employees.map((employee) => (
                      <Select.Option
                        value={employee.name}
                        key={employee.user_id}
                        label={employee.name}
                      >
                        <Capitalize>{employee.full_name}</Capitalize>
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col md={12} xs={24}>
                <Form.Item
                  label={
                    <span>
                      Categoria
                      <Tooltip title="Tipo do produto">
                        <InfoCircleIcon />
                      </Tooltip>
                    </span>
                  }
                  name="category_id"
                  initialValue={
                    state?.productsCategory?.find(
                      (item) =>
                        item?.name?.toLowerCase() === "custo com pessoas"
                    )?.id
                  }
                  rules={[{ required: true, message: "Campo obrigatório" }]}
                >
                  <Select
                    disabled
                    placeholder="Encargo"
                    onChange={(value) => {
                      form.setFieldsValue({ charge_id: value });
                    }}
                  >
                    {state?.productsCategory
                      ?.filter(
                        (item) =>
                          item?.name?.toLowerCase() === "custo com pessoas"
                      )
                      ?.map((productCategory) => (
                        <Select.Option
                          value={productCategory.id}
                          key={productCategory.id}
                        >
                          {productCategory?.name}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col xs={24}>
                <Title>Pagamento</Title>
              </Col>
            </Row>
            {items.map((item, index) => (
              <>
                <Row gutter={8} key={item.key}>
                  <Col md={4} xs={12}>
                    <Form.Item
                      label={
                        <span>
                          Valor
                          <Tooltip title="Valor do pagamento">
                            <InfoCircleIcon />
                          </Tooltip>
                          <Validate>*</Validate>
                        </span>
                      }
                      name={`payment_amount${index}`}
                      rules={[
                        {
                          validator: () => {
                            return item?.payment_amount
                              ? Promise.resolve()
                              : Promise.reject("Campo obrigatório");
                          },
                        },
                      ]}
                    >
                      <MonetaryInput2
                        style={{ height: "2.5rem" }}
                        getValue={(value) => handleValueChange(index, value)}
                        defaultValue={item.payment_amount}
                      />
                    </Form.Item>
                  </Col>

                  <Col md={4} xs={12}>
                    <Form.Item
                      label={
                        <span>
                          Encargo
                          <Tooltip title="Encargo da Categoria Custo com Pessoas">
                            <InfoCircleIcon />
                          </Tooltip>
                        </span>
                      }
                      name={`product_id${index}`}
                      rules={[{ required: true, message: "Campo obrigatório" }]}
                    >
                      <Select
                        showSearch
                        placeholder="Adicione o encargo"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toString()
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        onChange={(value) => {
                          const newItems = [...items];
                          newItems[index].product_id = +value;
                          setItems(newItems);

                          form.setFieldsValue({
                            [`product_id${index}`]: value,
                          });
                        }}
                      >
                        {state?.productsCategory
                          ?.find(
                            (item) =>
                              item?.name.toLowerCase() === "custo com pessoas"
                          )
                          ?.products?.map((product) => (
                            <Select.Option
                              value={product.id}
                              key={product.id}
                              label={product.name}
                            >
                              {product.name}
                            </Select.Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col md={4} xs={24}>
                    <Form.Item
                      name={`payment_method${index}`}
                      label={
                        <span>
                          Forma de Pagamento
                          <Tooltip title="Método de pagamento">
                            <InfoCircleIcon />
                          </Tooltip>
                        </span>
                      }
                      rules={[{ required: true, message: "Campo obrigatório" }]}
                    >
                      <Select
                        placeholder="Selecione uma opção"
                        onChange={(value) => {
                          handleItemChange(+value, "payment_method", index);
                        }}
                        value={item?.payment_method!}
                      >
                        {OrderPaymentType?.map((type) => (
                          <Select.Option value={type.value} key={type.value}>
                            {type.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col md={4} xs={24}>
                    <Form.Item
                      label={
                        <span>
                          Data de Pgto.
                          <Tooltip title="Data de pagamento">
                            <InfoCircleIcon />
                          </Tooltip>
                        </span>
                      }
                      name={`payment_date${index}`}
                      rules={[{ required: true, message: "Campo obrigatório" }]}
                    >
                      <DatePicker
                        placeholder="dd/mm/aaaa"
                        format="DD/MM/YYYY"
                        name="payment_date"
                        locale={ptBR}
                        onChange={(value) =>
                          handleItemChange(value, "payment_date", index)
                        }
                      />
                    </Form.Item>
                  </Col>

                  <Col md={4} xs={24}>
                    <Form.Item
                      label={
                        <span>
                          Valor Adicional
                          <Tooltip title="Valor Adicional da Compra">
                            <InfoCircleIcon />
                          </Tooltip>
                        </span>
                      }
                      name={`additional_value${index}`}
                    >
                      <MonetaryInput2
                        style={{ height: "2.5rem" }}
                        getValue={(value) =>
                          handleAdditionalValueChange(index, value)
                        }
                        defaultValue={item.additional_value}
                      />
                    </Form.Item>
                  </Col>

                  <Col md={items.length > 1 ? 3 : 4} xs={24}>
                    <Form.Item
                      label={
                        <span>
                          Desconto
                          <Tooltip title="Qualquer desconto no valor da compra">
                            <InfoCircleIcon />
                          </Tooltip>
                        </span>
                      }
                      name={`discounts${index}`}
                    >
                      <MonetaryInput2
                        style={{ height: "2.5rem" }}
                        getValue={(value) => handleDiscountChange(index, value)}
                        defaultValue={item.discounts}
                      />
                    </Form.Item>
                  </Col>

                  {items.length > 1 && (
                    <ColIcon md={1} xs={24}>
                      <Form.Item label=" ">
                        <TrashIcon onClick={() => removeItem(item.key)} />
                      </Form.Item>
                    </ColIcon>
                  )}
                </Row>
              </>
            ))}

            <Row>
              <NewRowButton onClick={addItem}>
                + Adicionar novo item
              </NewRowButton>
            </Row>

            <Row gutter={8}>
              <Col md={4} xs={24}>
                <Form.Item
                  label={
                    <span>
                      Valor Total
                      <Tooltip title="Valor total do encargo">
                        <InfoCircleIcon />
                      </Tooltip>
                      <Validate>*</Validate>
                    </span>
                  }
                  name={`total_value`}
                >
                  <Input
                    disabled
                    readOnly
                    value={`R$ ${total.totalValue.toFixed(2)}`}
                    placeholder={`R$ ${total.totalValue.toFixed(2)}`}
                  />
                </Form.Item>
              </Col>
              <Col md={4} xs={24}>
                <Form.Item
                  label={
                    <span>
                      Mês de Competência
                      <Tooltip title="Mês em que a conta será lançada">
                        <InfoCircleIcon />
                      </Tooltip>
                    </span>
                  }
                  name="month_competence"
                  rules={[{ required: true, message: "Campo obrigatório" }]}
                >
                  <DatePicker
                    placeholder="mm/aaaa"
                    format="MM/YYYY"
                    picker="month"
                    name="month_competence"
                    locale={ptBR}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Title>Observações e Anexos</Title>
            <Row gutter={12}>
              <Col xs={24}>
                <Form.Item
                  name="observation"
                  label={
                    <span>
                      Observação
                      <Tooltip title="Comentário sobre a compra">
                        <InfoCircleIcon />
                      </Tooltip>
                    </span>
                  }
                >
                  <Input.TextArea
                    placeholder="Adicione uma observação"
                    name="observation"
                    maxLength={255}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={12}>
              <ColAnexo xs={24}>
                <Form.Item label="Anexos">
                  <input
                    type="file"
                    maxLength={1}
                    id="input_file"
                    accept=".pdf, .jpg, .jpeg, .png"
                  />
                  {fileData && (
                    <ContentFiles>
                      <span>{fileData.url_file.split("/").pop()}</span>
                      <TrashIconUpload onClick={() => setFileData(null)} />
                    </ContentFiles>
                  )}
                </Form.Item>
              </ColAnexo>
            </Row>
            <Footer>
              <Button
                backgroundColor="var(--orange-350)"
                color="var(--white)"
                border="none"
                onClick={() => handleSubmit()}
              >
                Salvar
              </Button>
              <Link
                to={{
                  pathname: "/people",
                  state: {
                    store_param: store,
                    shouldSearch: true,
                    data_inicial: currentMonthStart,
                    data_final: currentMonthEnd,
                  },
                }}
              >
                <Button
                  backgroundColor="transparent"
                  color="var(--gray-650)"
                  border="1px solid var(--gray-650)"
                >
                  Cancelar
                </Button>
              </Link>
            </Footer>
          </Content>
        </Form>
      </Container>
    </PageContainer>
  );
};

export default AddNewPayment;
