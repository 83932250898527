import React, { useEffect, useState } from "react";
import { Empty, message, Modal, notification, Radio, Tabs } from "antd";
import apiAuth from "../../../services/apiAuth";
import PageContainer from "../../../containers/PageContainer";

import { Page } from "../../../models/Page";
import { Order as OrderModel } from "../../../models/Order/Order";
import OrdersList from "../OrdersList";
import {
  Button,
  Container,
  Header,
  CheckboxContainer,
  Checkbox,
  Content,
  ContentConsolidate,
  ContainerButton,
  ContainerConsolidate,
  ContentCheckBox,
  BoxSelectSystem,
  BoxSystem,
} from "./styles";
import Pagination from "../../../components/Pagination";
import { getTokenInfo } from "../../../services/auth";
import api from "../../../services/api";
import Centralizer from "../../../containers/Centralizer";
import Spinner from "../../../components/Spinner";
import { CategoryModel } from "../../../models/CategoryModel";
import { OrderItem } from "../../../models/Order/OrderItem";
import { MessageType } from "antd/lib/message";

const OrdersSendBlingPY: React.FC = () => {
  const [loadingStore, setLoadingStore] = useState<boolean>(false);
  const [loadingBling, setLoadingBling] = useState<boolean>(false);
  const [paginate, setPaginate] = useState<Page>({
    page: 1,
    size: 60,
    totalElements: 0,
  });

  const [consolidatedPaginate, setConsolidatedPaginate] = useState<Page>({
    page: 1,
    size: 2,
    totalElements: 0,
  });
  const [geladoChecked, setGeladoChecked] = useState(false);
  const [secoChecked, setSecoChecked] = useState(false);
  const [todosChecked, setTodosChecked] = useState(false);
  const [categories, setCategories] = useState<CategoryModel[]>([]);

  const [selectedOrderIds, setSelectedOrderIds] = useState<number[]>([]);
  const [paraguayCompanyIds, setParaguayCompanyIds] = useState<number[]>([]);
  const [companyIdsReady, setCompanyIdsReady] = useState<boolean>(false);
  const [ordersToConsolidateBling, setOrdersToConsolidateBling] = useState<
    OrderModel[]
  >([]);
  const [exportedOrderGroupsBling, setExportedOrderGroupsBling] = useState<{
    [key: string]: OrderModel[];
  }>({});

  const [ordersToConsolidateTotvs, setOrdersToConsolidateTotvs] = useState<
    OrderModel[]
  >([]);
  const [exportedOrderGroupsTotvs, setExportedOrderGroupsTotvs] = useState<{
    [key: string]: OrderModel[];
  }>({});
  const [selectedGroups, setSelectedGroups] = useState<string[]>([]);
  const [activeTabKey, setActiveTabKey] = useState<string>("1");
  const [selectedSystem, setSelectedSystem] = useState<"bling" | "totvs">(
    "totvs"
  );

  const handleSelectedOrders = (newSelectedOrderIds: number[]) => {
    setSelectedOrderIds(newSelectedOrderIds);
  };

  const handleGroupSelect = (groupId: string) => {
    setSelectedGroups((prevSelectedGroups) => {
      if (prevSelectedGroups.includes(groupId)) {
        return prevSelectedGroups.filter((id) => id !== groupId);
      } else {
        return [groupId];
      }
    });
  };

  useEffect(() => {
    const fetchCompanyIds = async () => {
      setLoadingStore(true);
      try {
        const user = await getTokenInfo();
        const { data } = await apiAuth.get(`/companyUser/${user?.id}/user`);
        const companies = data.content;

        const paraguayCompanies = companies.filter(
          (company) => company.company.country?.toLowerCase() === "paraguai"
        );

        const paraguayCompanyIds = paraguayCompanies.map(
          (company) => company.company.id
        );

        setParaguayCompanyIds(paraguayCompanyIds);
        setCompanyIdsReady(true);
      } catch (error) {
        const _description =
          //@ts-ignore
          error.response?.data || "Erro ao buscar empresas do Paraguai";
        notification.error({
          message: "Erro ao buscar empresas",
          description: _description,
          duration: 5,
        });
      } finally {
        setLoadingStore(false);
      }
    };

    fetchCompanyIds();
  }, []);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const {
          data: { content },
        } = await api.get(`/product_categories/products/orders`);

        setCategories(content);
      } catch (error) {
        //@ts-ignore
        const _description = error.data;

        notification.error({
          message: "Erro ao buscar categorias",
          description: _description,
          duration: 5,
        });
      }
    };
    fetchCategories();
  }, []);

  const fetchAllStoresOrders = async (system: "bling" | "totvs") => {
    if (!companyIdsReady || paraguayCompanyIds.length === 0) {
      setLoadingStore(false);
      return;
    }
    setLoadingStore(true);

    try {
      const storeIds = paraguayCompanyIds.join(",");
      const { data: pagination } = await api.get(
        `/orders?page=${paginate.page}&size=${paginate.size}&stores_ids=${storeIds}`
      );
      const { totalElements, content } = pagination;

      setPaginate((oldValues) => ({ ...oldValues, totalElements }));

      const toConsolidate: OrderModel[] = [];
      const exportedGroups: { [key: string]: OrderModel[] } = {};

      const processedOrderIds = new Set<number>();

      content.forEach((order: OrderModel) => {
        if (processedOrderIds.has(order.id)) return;

        processedOrderIds.add(order.id);

        if (system === "bling") {
          const hasSystemId = order.orderItems.some(
            (item: OrderItem) => item.sale_order_bling_id
          );

          if (!hasSystemId) {
            toConsolidate.push(order);
          } else {
            const groupId = order.orderItems[0].sale_order_bling_id || "null";

            if (!exportedGroups[groupId]) {
              exportedGroups[groupId] = [];
            }
            exportedGroups[groupId].push(order);
          }
        } else if (system === "totvs") {
          const hasSystemId = !!order.totvs_id;

          if (!hasSystemId) {
            toConsolidate.push(order);
          } else {
            const groupId = order.totvs_id || "null";

            if (!exportedGroups[groupId]) {
              exportedGroups[groupId] = [];
            }
            exportedGroups[groupId].push(order);
          }
        }
      });

      const totalConsolidatedOrders = Object.keys(exportedGroups).length;
      setConsolidatedPaginate((oldValues) => ({
        ...oldValues,
        page: oldValues.page || 1,
        size: oldValues.size || 60,
        totalElements: totalConsolidatedOrders,
      }));

      if (system === "bling") {
        setOrdersToConsolidateBling(toConsolidate);
        setExportedOrderGroupsBling(exportedGroups);
      } else if (system === "totvs") {
        setOrdersToConsolidateTotvs(toConsolidate);
        setExportedOrderGroupsTotvs(exportedGroups);
      }
    } catch (error) {
      const _description =
        //@ts-ignore
        error.response?.data || "Erro ao buscar pedidos do Paraguai";
      notification.error({
        message: "Erro ao buscar pedidos",
        description: _description,
        duration: 5,
      });
    } finally {
      setLoadingStore(false);
    }
  };

  const ordersToConsolidate =
    selectedSystem === "bling"
      ? ordersToConsolidateBling
      : ordersToConsolidateTotvs;

  const exportedOrderGroups =
    selectedSystem === "bling"
      ? exportedOrderGroupsBling
      : exportedOrderGroupsTotvs;

  useEffect(() => {
    setLoadingStore(true);
    fetchAllStoresOrders(selectedSystem);
  }, [
    paginate.page,
    paginate.size,
    consolidatedPaginate.page,
    paraguayCompanyIds,
    companyIdsReady,
    selectedSystem,
  ]);

  useEffect(() => {
    if (activeTabKey === "1") {
      setLoadingStore(true);
      fetchAllStoresOrders(selectedSystem);
    }
  }, [
    paginate.page,
    paginate.size,
    activeTabKey,
    paraguayCompanyIds,
    companyIdsReady,
    selectedSystem,
  ]);

  useEffect(() => {
    if (activeTabKey === "2") {
      setLoadingStore(true);
      fetchAllStoresOrders(selectedSystem);
    }
  }, [
    consolidatedPaginate.page,
    consolidatedPaginate.size,
    activeTabKey,
    paraguayCompanyIds,
    companyIdsReady,
    selectedSystem,
  ]);

  const handleCheckboxChange = (type: number) => {
    let newSelectedOrderIds: number[] = [];

    if (type === 0) {
      setGeladoChecked(!geladoChecked);
      setSecoChecked(false);
      setTodosChecked(false);
      newSelectedOrderIds = !geladoChecked
        ? ordersToConsolidate
            .filter((order) => order.type_of_load === 0)
            .map((order) => order.id)
        : [];
    } else if (type === 1) {
      setGeladoChecked(false);
      setSecoChecked(!secoChecked);
      setTodosChecked(false);
      newSelectedOrderIds = !secoChecked
        ? ordersToConsolidate
            .filter((order) => order.type_of_load === 1)
            .map((order) => order.id)
        : [];
    } else if (type === 3) {
      setGeladoChecked(false);
      setSecoChecked(false);
      setTodosChecked(!todosChecked);
      newSelectedOrderIds = !todosChecked
        ? ordersToConsolidate.map((order) => order.id)
        : [];
    }

    handleSelectedOrders(newSelectedOrderIds);
  };

  const parseErrorMessage = (errorData) => {
    if (
      errorData.error &&
      errorData.error.error &&
      Array.isArray(errorData.error.error.fields)
    ) {
      return errorData.error.error.fields.map((field) => field.msg).join("\n");
    }
    if (typeof errorData === "string") return errorData;
    if (typeof errorData.error === "string") return errorData.error;
    if (errorData.error && typeof errorData.error.message === "string")
      return errorData.error.message;
    if (typeof errorData.message === "string") return errorData.message;
    if (Array.isArray(errorData.error)) return errorData.error.join("\n");
    return "Erro ao enviar pedidos";
  };

  function downloadLogs(_logs: any[]) {
    const content = _logs.reduce(
      (total, log) => total + `[${log.id}]: ${log.message}\n`,
      ""
    );

    const blob = new Blob([content], { type: "text/plain" });

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "totvsLogs.txt";

    link.click();

    URL.revokeObjectURL(link.href);
  }

  const handleConsolidate = async () => {
    let hideMessage: MessageType | null = null;
    if (selectedSystem === "bling") {
      hideMessage = message.loading({
        content: `Aguarde, estamos enviando os pedidos para o ${
          selectedSystem === "bling" ? "Bling" : "Totvs"
        }...`,
        duration: 0,
      });
    }
    setLoadingBling(true);

    const handleError = (error): string => {
      let _description = "Erro ao enviar pedidos";

      //@ts-ignore
      if (error.response && error.response.data) {
        //@ts-ignore
        const errorData = error.response.data;
        _description = parseErrorMessage(errorData);
      }
      return _description;
    };

    try {
      const orderIdsToSend = selectedOrderIds;

      const selectedOrders = ordersToConsolidate.filter((order) =>
        orderIdsToSend.includes(order.id)
      );

      const orderTypes = new Set(
        selectedOrders.map((order) => order.type_of_load)
      );

      if (selectedSystem === "totvs" && orderTypes.size > 1) {
        notification.error({
          message: "Erro ao enviar pedidos",
          description: "Selecione pedidos do mesmo tipo (SECO ou GELADO).",
          duration: 5,
        });
        return;
      }

      if (selectedSystem === "bling") {
        await api.patch(`/orders/paraguay/send-to-bling`, {
          ordersIds: orderIdsToSend,
        });

        notification.success({
          message: "Sucesso",
          description: "Pedidos enviados para o Bling com sucesso.",
          duration: 5,
        });
      } else if (selectedSystem === "totvs") {
        Modal.confirm({
          title: "Integração com a Totvs?",
          content: "Deseja continuar e integrar esses pedidos à Totvs?",
          okText: "Sim",
          okType: "primary",
          cancelText: "Não",
          async onOk() {
            const errors: any[] = [];
            let count = 0;
            for (const orderId of orderIdsToSend) {
              try {
                count += 1;
                const countMessasge = message.loading({
                  content: `Enviando ${count} de ${orderIdsToSend.length}`,
                  duration: 0,
                });
                await api.post(`/order/totvs/send-order-py`, {
                  order_ids: [orderId],
                });
                countMessasge();
              } catch (error) {
                const vhsysId = selectedOrders.find(
                  (_order) => _order.id === orderId
                )?.vhsys;
                const message = handleError(error);
                errors.push({ id: vhsysId, message });
              }
            }
            if (errors.length) {
              downloadLogs(errors);
              notification.warn({
                message: "Erro durante a Integração",
                description:
                  "Houveram erros durante a integração, um arquivo de logs foi disponibilizado para consulta.",
                duration: 5,
              });
            } else {
              notification.success({
                message: "Sucesso",
                description: "Pedidos enviados para o Totvs com sucesso.",
                duration: 5,
              });
            }
          },
        });
      }

      await fetchAllStoresOrders(selectedSystem);
      setSelectedOrderIds([]);
    } catch (error) {
      const description = handleError(error);
      notification.error({
        message: "Erro ao enviar pedidos",
        description: (
          <strong>
            <pre>{description}</pre>
          </strong>
        ),
        duration: 5,
        style: { width: "700px" },
      });
    } finally {
      if (hideMessage) hideMessage();
      setLoadingBling(false);
    }
  };

  const handleRefundSelectedGroups = async () => {
    const ordersToRefund = selectedGroups.reduce((acc, groupId) => {
      const groupOrders = exportedOrderGroups[groupId].map((order) => order.id);
      return [...acc, ...groupOrders];
    }, [] as number[]);

    const selectedOrders = ordersToRefund
      .map((orderId) =>
        [
          ...ordersToConsolidate,
          ...Object.values(exportedOrderGroups).flat(),
        ].find((order) => order.id === orderId)
      )
      .filter(Boolean) as OrderModel[];

    const orderTypes = new Set(
      selectedOrders.map((order) => order.type_of_load)
    );

    if (orderTypes.size > 1) {
      notification.error({
        message: "Erro ao estornar pedidos",
        description:
          "Selecione pedidos do mesmo tipo (SECO ou GELADO) para estornar.",
        duration: 5,
      });
      return;
    }

    Modal.confirm({
      title: "Confirmar Estorno",
      content: `Você está prestes a estornar todos os pedidos dos grupos selecionados do ${
        selectedSystem === "bling" ? "Bling" : "Totvs"
      }. Tem certeza que deseja continuar?`,
      okText: "Sim",
      cancelText: "Cancelar",
      onOk: async () => {
        setLoadingStore(true);

        try {
          if (selectedSystem === "bling") {
            await api.delete(`/orders/paraguay/remove-to-bling`, {
              data: { ordersIds: ordersToRefund },
            });

            notification.success({
              message: "Sucesso",
              description:
                "Todos os pedidos dos grupos selecionados foram estornados com sucesso do Bling.",
              duration: 5,
            });
          } else if (selectedSystem === "totvs") {
            await api.post(`/order/totvs/remove-order-py`, {
              order_ids: ordersToRefund,
            });

            notification.success({
              message: "Sucesso",
              description:
                "Todos os pedidos dos grupos selecionados foram estornados com sucesso do Totvs.",
              duration: 5,
            });
          }

          await fetchAllStoresOrders(selectedSystem);
          setSelectedGroups([]);
        } catch (error) {
          const description =
            //@ts-ignore
            error.response?.data?.error?.message ||
            //@ts-ignore
            error.response?.error?.message ||
            //@ts-ignore
            error.response?.data?.message ||
            //@ts-ignore
            error.response?.message ||
            //@ts-ignore
            error.message ||
            `Ocorreu um erro ao tentar estornar os pedidos da Totvs. Tente novamente mais tarde ou contate o suporte.`;

          notification.error({
            message: "Erro ao estornar pedidos",
            description:
              selectedSystem === "bling"
                ? `Ocorreu um erro ao tentar estornar os pedidos do Bling. Tente novamente mais tarde ou contate o suporte.`
                : description,
            duration: 5,
          });
        } finally {
          setLoadingStore(false);
        }
      },
    });
  };

  const currentPage = consolidatedPaginate.page ?? 1;
  const pageSize = consolidatedPaginate.size ?? 2;

  return (
    <PageContainer route="Consolidar Pedidos do Paraguay">
      <Container>
        {loadingBling ? (
          <Centralizer>
            <Spinner />
          </Centralizer>
        ) : (
          <>
            <BoxSystem>
              <Radio.Group
                onChange={(e) => setSelectedSystem(e.target.value)}
                value={selectedSystem}
              >
                <Radio.Button value="bling">Bling</Radio.Button>
                <Radio.Button value="totvs">Totvs</Radio.Button>
              </Radio.Group>
            </BoxSystem>
            <Tabs type="card" onChange={(key) => setActiveTabKey(key)}>
              <Tabs.TabPane tab="Consolidar e Enviar" key="1">
                <Header>
                  <CheckboxContainer>
                    <h2>Lista de Pedidos</h2>
                    <div>
                      <Checkbox
                        type="checkbox"
                        checked={geladoChecked}
                        onChange={() => handleCheckboxChange(0)}
                        disabled={
                          todosChecked ||
                          secoChecked ||
                          loadingBling ||
                          loadingStore
                        }
                      >
                        Gelado
                      </Checkbox>
                      <Checkbox
                        checked={secoChecked}
                        onChange={() => handleCheckboxChange(1)}
                        disabled={
                          todosChecked ||
                          geladoChecked ||
                          loadingBling ||
                          loadingStore
                        }
                      >
                        Seco
                      </Checkbox>
                      {selectedSystem === "bling" && (
                        <Checkbox
                          checked={todosChecked}
                          onChange={() => handleCheckboxChange(3)}
                          disabled={
                            secoChecked ||
                            geladoChecked ||
                            loadingBling ||
                            loadingStore
                          }
                        >
                          Todos
                        </Checkbox>
                      )}
                    </div>
                  </CheckboxContainer>
                  <BoxSelectSystem>
                    <Button
                      disabled={
                        selectedOrderIds.length === 0 ||
                        loadingBling ||
                        loadingStore
                      }
                      onClick={handleConsolidate}
                      loading={loadingBling}
                    >
                      Consolidar e Enviar ao{" "}
                      {selectedSystem === "bling" ? "Bling" : "Totvs"}
                    </Button>
                  </BoxSelectSystem>
                </Header>
                <Content>
                  {loadingStore ? (
                    <Centralizer>
                      <Spinner />
                    </Centralizer>
                  ) : (
                    <>
                      <OrdersList
                        loadingBlingPY={loadingBling}
                        orders={ordersToConsolidate}
                        setShouldSearch={() => {}}
                        categories={categories}
                        setVisibleEdit={() => {}}
                        setOrderToEdit={() => {}}
                        isStoreActive={true}
                        showCheckboxes={true}
                        selectedOrderIds={selectedOrderIds}
                        onSelectedChange={handleSelectedOrders}
                        countryPY={true}
                      />
                      <Pagination
                        disabled={loadingBling}
                        setStateSearch={() => {}}
                        setPaginate={setPaginate}
                        defaultPageSize={60}
                        showSizeChanger={false}
                        current={paginate.page}
                        totalElements={paginate.totalElements}
                      />
                    </>
                  )}
                </Content>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Pedidos Consolidados" key="2">
                <ContentConsolidate>
                  {loadingStore ? (
                    <Centralizer>
                      <Spinner />
                    </Centralizer>
                  ) : (
                    <>
                      <ContainerButton>
                        <Button
                          onClick={handleRefundSelectedGroups}
                          disabled={selectedGroups.length === 0}
                        >
                          Estornar Selecionados
                        </Button>
                      </ContainerButton>
                      {Object.keys(exportedOrderGroups).length === 0 ? (
                        <Centralizer>
                          <Empty description="Nenhum pedido exportado disponível." />
                        </Centralizer>
                      ) : (
                        Object.keys(exportedOrderGroups)
                          .slice(
                            (currentPage - 1) * pageSize,
                            currentPage * pageSize
                          )
                          .map((groupId) => (
                            <ContainerConsolidate key={groupId}>
                              <ContentCheckBox>
                                <Checkbox
                                  checked={selectedGroups.includes(groupId)}
                                  onChange={() => handleGroupSelect(groupId)}
                                  disabled={
                                    selectedGroups.length > 0 &&
                                    !selectedGroups.includes(groupId)
                                  }
                                >
                                  {selectedSystem === "bling"
                                    ? `ID grupo exportado para o Bling: ${groupId}`
                                    : `ID do Pedido no Totvs: ${groupId}`}
                                </Checkbox>
                              </ContentCheckBox>
                              <OrdersList
                                loadingBlingPY={loadingBling}
                                orders={exportedOrderGroups[groupId]}
                                setShouldSearch={() => {}}
                                categories={categories}
                                setVisibleEdit={() => {}}
                                setOrderToEdit={() => {}}
                                isStoreActive={true}
                                showCheckboxes={false}
                                selectedOrderIds={[]}
                                onSelectedChange={() => {}}
                                countryPY={true}
                              />
                            </ContainerConsolidate>
                          ))
                      )}
                      <Pagination
                        disabled={loadingStore}
                        setStateSearch={() => {}}
                        setPaginate={setConsolidatedPaginate}
                        defaultPageSize={consolidatedPaginate.size}
                        showSizeChanger={false}
                        current={consolidatedPaginate.page}
                        totalElements={consolidatedPaginate.totalElements}
                      />
                    </>
                  )}
                </ContentConsolidate>
              </Tabs.TabPane>
            </Tabs>
          </>
        )}
      </Container>
    </PageContainer>
  );
};

export default OrdersSendBlingPY;
