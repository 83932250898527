import styled, { css } from "styled-components";

import {
  Button as ButtonAnt,
  Modal as ModalAnt,
  Input as InputAnt,
  Select as SelectAnt,
  Table as TableAnt,
  Checkbox as CheckboxAnt,
} from "antd";

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  height: 15%;
  width: 100%;
`;

export const Modal = styled(ModalAnt)`
  height: fit-content;
`;

export const Input = styled(InputAnt)`
  height: 2rem;
  width: 70%;
`;

export const InputSearch = styled(InputAnt)`
  height: 3.25rem;
  width: 40%;
  margin: 0 0.5rem;
`;

interface IColorSelection {
  customColor: string;
}
export const ColorSelection = styled.div<IColorSelection>`
  height: 2rem;
  width: 20%;
  border: 1px solid;
  cursor: pointer;
  ${({ customColor }) => css`
    background-color: ${customColor};
  `}
`;

export const Button = styled(ButtonAnt)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.25rem;
  width: fit-content;
  color: white;
  background: var(--orange-gradient);
  border: 1px solid white;
  border-radius: 0.5rem;
  margin: 0;
  :hover,
  :focus {
    color: white;
    border: 1px solid white;
    background: var(--orange-gradient);
    opacity: 80%;
  }
`;

export const ProductTagModalContainer = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  height: fit-content;
  margin: 1.5rem 0;
`;

export const ColorPickerContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 5px 0;
  width: 100%;
  height: fit-content;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 85%;
  width: 100%;
`;

export const SelectionContent = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  height: 3.25rem;
  width: 50%;

  button {
    width: 50%;
  }
`;

export const SearchContent = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  height: 3.25rem;
  width: 50%;
`;

export const ListContent = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  height: 90%;
  width: 100%;
`;

export const Select = styled(SelectAnt)`
  width: 48%;
  margin-right: 0.5rem;
  .ant-select-selection-item {
    text-transform: uppercase !important;
  }
`;

export const Table = styled(TableAnt)`
  width: 100%;
`;

export const Checkbox = styled(CheckboxAnt)``;
