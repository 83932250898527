import styled from 'styled-components';

import { Button as ButtonAnt, Modal as ModalAnt, Table as TableAnt } from "antd"
import { Download, InfoCircle } from '../../../styles/Icons';

export const ButtonTable = styled(ButtonAnt)`
  display: flex;
  width: 15.9375rem; 
  height: 3.25rem; 
  padding: 1rem 2.1875rem; 
  justify-content: center;
  align-items: center;

  border-radius: 5px;
  border: none;
  background-color: var(--orange-350); 
  color:  var(--white);

  :hover {
    opacity: 70%;
    border: 1px solid var(--orange-350);
    color: var(--orange-350);
  }
`;

export const ContentTopInfo = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    @media only screen and (max-width: 768px) {
      flex-direction: column;
    }
`;

export const ItemInfo = styled.div`
    display: flex;
    flex-direction: column;

    @media only screen and (max-width: 768px) {
      width: 100%;
    }
`;

export const ContentFooter = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: end;
`;

export const Table = styled(TableAnt)`  
   margin-bottom: 1rem;
    .ant-table table {
        width: 100%;
        text-align: left;
        border-radius: 0;
        border-collapse: separate;
        border-spacing: 0;
        border: 1px solid var(--gray-25);
        border-radius: 8px;
        margin-top: 1rem;
    }

    .ant-table-thead > tr > th {
      background: var(--white);
    }
`;

export const InfoCapitalized = styled.span`
  text-transform: capitalize;
`;

export const InfoCircleIcon = styled(InfoCircle)`
  width: 15px;
  height: 15px;
  cursor: pointer;
  color: var(--c64-blue);
  margin-left: 10px;
`;

export const ContentTile = styled.div`
  display: flex;
  align-items: center;
`;

export const Label = styled.label`
  color: var(--blue-900);
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; 
`;

export const TextModal = styled.span`
  color: rgba(4, 15, 46, 0.60);
  font-style: normal;
  font-weight: 400;

  margin-top: .3rem;

  text-transform: capitalize;
`;

export const DescriptionLabel = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 2%;
  white-space: nowrap;
`;

export const ContentDownload = styled.div`
  display: flex;
  align-items: center;
`;

export const DownloadIconBottom = styled(Download)`
  color: var(--orange-350);
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 5px;

  cursor: pointer;
`;

export const Modal = styled(ModalAnt)`
   max-height: 50rem;
  .ant-modal-header{
    padding: 16px 24px 0 !important;
  }
  .ant-modal-body {
      padding: 0 24px 15px !important;
  }
`;