import styled from "styled-components";

import { EditAlt } from "../../styles/Icons";

import { Select as SelectAnt } from "antd";

const { Option: OptionAnt } = SelectAnt;

export const Select = styled(SelectAnt) <{ height?: string }>`
  .ant-select-selection-item {
    display: flex;
    align-items: center;
    }

  .ant-select-selector{
    height: ${({ height }) => height ? "2.5rem" : "auto"} !important;
  }

  .pencilIcon {
    display: none;
  }
`;

export const Option = styled(OptionAnt)``;

export const OptionInfoContainer = styled.div`
  display: flex;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const OptionLabel = styled.label`
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 45%;
`;

export const OptionLabelCpnj = styled.label`
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 45%;
  color: var(--gray-170);
  font-size: .8rem;
  align-items: start;
`;

export const PencilIcon = styled(EditAlt)`
  width: 23px;
  height: 23px;
  fill: black;
  z-index: 10;

  cursor: pointer;
  :hover {
    fill: orange;
  }
  @media only screen and (max-width: 578px) {
    width: 18px;
    height: 18px;
  }
`;
