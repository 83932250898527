import React, { useEffect, useState } from "react";
import moment, { Moment } from "moment";
import { useLocation } from "react-router-dom";

import api from "../../services/api";

import {
  Dropdown,
  Empty,
  Form,
  Menu,
  Modal,
  notification,
  Select,
  Spin,
  Tooltip,
} from "antd";
import locale from "antd/es/date-picker/locale/pt_BR";

import PageContainer from "../../containers/PageContainer";
import Centralizer from "../../containers/Centralizer";
import { SelectMultipleIcon } from "../../containers/ShoppingList/styles";

import ShopImg from "../../assets/svg/shop-bill.svg";

import StoreSelectionPage from "../../components/StoreSelectionPage";
import DisabledFlag from "../../components/DisabledFlag";
import GetStore from "../../components/GetStore";

import { useStoreData } from "../../hooks/useStoreData";

import apiMercury from "../../services/apiMercury";
import { currencyFormater } from "../../services/currencyFormater";

import { AccountPayable } from "../../models/AccountsPayable";
import { Page } from "../../models/Page";
import { PaymentType } from "../../models/enums/PaymentType";

import { removeAccentsAndLowerCase } from "../../utils/removeAccentsAndCaseSensitive";
import { ProductCategory } from "../../models/ProductCategory";

import PartialPaymentModal from "./PartialPaymentModal";

import {
  Actions,
  AddIcon,
  BarcodeIcon,
  ButtonFilter,
  Col,
  Container,
  ContentTile,
  EmptyContent,
  FilterIcon,
  InfoCircleIcon,
  Input,
  MoneyIcon,
  MoreIcon,
  Row,
  StyledTd,
  Table,
  TableInfo,
  TransferIcon,
  InfoCapitalized,
  StyledLink,
  ContentEmptyId,
  MinusIcon,
  ButtonCancel,
  ButtonSave,
  ContentModal,
  ContentBorderColor,
  ContentTileID,
  RangePickerElement,
  Th,
  HeaderRow,
  BodyRow,
  DisabledSpan,
  PixIcon,
  CreditCardIcon,
  DebitCardIcon,
  ContentHeader,
} from "./styles";

interface LocationState {
  store_param?: number;
}

const BillsToPay = () => {
  const location = useLocation<LocationState>();

  const [store, setStore] = useState<number | undefined | null>();
  const [shouldSearch, setShouldSearch] = useState<boolean>(true);

  const [filteredAccountsPayable, setFilteredAccountsPayable] = useState<
    AccountPayable[]
  >([]);
  const [selectedFilter, setSelectedFilter] = useState<null | string>(null);
  const [filterResults, setFilterResults] = useState<{
    overdueAccount: number;
    today: number;
    openAccount: number;
    paidAccount: number;
    totalOnPeriod: number;
  }>({
    overdueAccount: 0,
    today: 0,
    openAccount: 0,
    paidAccount: 0,
    totalOnPeriod: 0,
  });

  const [productsCategory, setProductsCategory] = useState<ProductCategory[]>(
    []
  );

  const [accountsPayable, setAccountsPayable] = useState<AccountPayable[]>([]);
  const [loading, setLoading] = useState(false);
  const [loadingTotals, setLoadingTotals] = useState(false);
  const [openFilterModal, setOpenFilterModal] = useState(false);

  const [showPartialPaymentModal, setShowPartialPaymentModal] = useState(false);

  const [searchValue, setSearchValue] = useState<string>("");

  const [selectedBill, setSelectedBill] = useState<AccountPayable | undefined>(
    undefined
  );

  const [selectedCategory, setSelectedCategory] = useState<
    number | undefined
  >();
  const [selectedDate, setSelectedDate] = useState<{
    initial_date: Moment | null;
    final_date: Moment | null;
  }>({
    initial_date: moment().startOf("month"),
    final_date: moment().endOf("month"),
  });

  const { isStoreActive } = useStoreData(store);
  const [paginate, setPaginate] = useState<Page>({
    page: 1,
    size: 10,
    totalElements: 0,
  });

  const { page, size, totalElements } = paginate;

  useEffect(() => {
    if (location.state && location.state.store_param) {
      setStore(location.state.store_param);
    }
  }, [location.state]);

  const getAccountsPayable = async () => {
    let URL = `/accounts-payable?page=${page}&size=${size}&store_id=${store}`;

    let data_inicial = moment(selectedDate.initial_date).format("DD/MM/YYYY");
    let data_final = moment(selectedDate.final_date).format("DD/MM/YYYY");

    URL += `&data_inicial=${data_inicial}&data_final=${data_final}`;

    if (selectedCategory) {
      URL += `&category_id=${selectedCategory}`;
    }
    if (selectedFilter) {
      if (selectedFilter === "overdue")
        URL += `&payableOverdue=${moment().format("DD/MM/YYYY")}`;
      if (selectedFilter === "today")
        URL += `&payableDueToday=${moment().format("DD/MM/YYYY")}`;
      if (selectedFilter === "paid") URL += `&payment_status=true`;
      if (selectedFilter === "open") URL += `&payableFalse=false`;
    }

    try {
      setLoading(true);
      const {
        data: { content, totalElements },
      } = await apiMercury.get(URL);
      setAccountsPayable(content);
      setFilteredAccountsPayable(content);

      setPaginate((oldValues) => ({ ...oldValues, totalElements }));
    } catch (error) {
      //@ts-ignore
      const _description = error?.response?.data?.error?.message;

      notification.error({
        message: `Oops, ocorreu um erro ao buscar as Contas a Pagar.`,
        description: _description,
        duration: 5,
      });
    } finally {
      setLoading(false);
      setShouldSearch(false);
    }
  };

  useEffect(() => {
    if (store && shouldSearch) getAccountsPayable();
  }, [store, shouldSearch, page]);

  useEffect(() => {
    const filterAccountsPayable = async () => {
      try {
        setLoadingTotals(true);
        let data_inicial = moment().startOf("month").format("01/MM/YYYY");
        let data_final = moment().endOf("month").format("DD/MM/YYYY");

        if (selectedDate.initial_date && selectedDate.final_date) {
          data_inicial = moment(selectedDate.initial_date).format("DD/MM/YYYY");
          data_final = moment(selectedDate.final_date).format("DD/MM/YYYY");
        }

        let URLFilter = `accounts-payable/summary?data_inicial=${data_inicial}&data_final=${data_final}&store_id=${store}`;

        if (selectedCategory) {
          URLFilter += `&category_id=${selectedCategory}`;
        }

        const {
          data: { content },
        } = await apiMercury.get(URLFilter);

        setFilterResults(() => ({
          overdueAccount: content?.totalOverdue,
          today: content?.totalDueToday,
          openAccount: content?.totalUnpaid,
          paidAccount: content?.totalPaid,
          totalOnPeriod: content?.totalPeriod,
        }));
      } catch (error) {
        //@ts-ignore
        const _description = error?.response?.data?.error?.message;

        notification.error({
          message: `Oops, ocorreu um erro ao buscar os totais.`,
          description: _description,
          duration: 5,
        });
      } finally {
        setLoadingTotals(false);
      }
    };
    if (store && shouldSearch) {
      filterAccountsPayable();
    }
  }, [store, shouldSearch]);

  useEffect(() => {
    const fetchProductsByCategory = async () => {
      try {
        const {
          data: { content },
        } = await api.get(`/product_categories/products/purchases`);

        const categories = content?.filter(
          (_category) =>
            _category.name === "custo fixo" ||
            _category.name === "custo com pessoas"
        );

        setProductsCategory(categories);
      } catch (error) {
        //@ts-ignore
        const _description = error?.response?.data?.error?.message;

        notification.error({
          message: `Oops, ocorreu um erro ao buscar os produtos.`,
          description: _description,
          duration: 5,
        });
      }
    };
    if (store) {
      fetchProductsByCategory();
    }
  }, [store]);

  useEffect(() => {
    const filterProviders = () => {
      const filtered = accountsPayable.filter((account) => {
        const provider = account.provider;
        if (provider) {
          const companyName = provider.company_name
            ? removeAccentsAndLowerCase(provider.company_name)
            : "";
          const fantasyName = provider.fantasy_name
            ? removeAccentsAndLowerCase(provider.fantasy_name)
            : "";

          return (
            companyName.includes(removeAccentsAndLowerCase(searchValue)) ||
            fantasyName.includes(removeAccentsAndLowerCase(searchValue))
          );
        }
        return false;
      });
      setFilteredAccountsPayable(filtered || []);
    };

    if (searchValue) {
      filterProviders();
    } else {
      setFilteredAccountsPayable(accountsPayable);
    }
  }, [searchValue, accountsPayable]);

  const deleteBill = (id: number) => {
    Modal.confirm({
      title: `Deletar a conta ID ${id}?`,
      content: "Deseja continuar e deletar essa conta a pagar?",
      okText: "Sim",
      okType: "primary",
      cancelText: "Não",
      centered: true,
      async onOk() {
        try {
          await apiMercury.delete(`/accounts-payable/${id}`);
          notification.success({
            message: `Conta deletada com sucesso.`,
            description: `A conta Nº ${id} foi excluída.`,
            duration: 5,
          });
          setShouldSearch(true);
        } catch (error) {
          //@ts-ignore
          const _description = error?.response?.data?.error?.message;

          notification.error({
            message: `Oops, ocorreu um erro ao deletar a conta Nº ${id}.`,
            description: _description,
            duration: 5,
          });
        }
      },
    });
  };

  const changeToStatusPaid = async (id: number) => {
    const payload = {
      payment_status: true,
    };
    try {
      await apiMercury.put(`/accounts-payable/${id}`, payload);

      notification.success({
        message: `Status atualizado com sucesso.`,
        description: `A conta Nº ${id} foi marcada como paga.`,
        duration: 5,
      });
      setShouldSearch(true);
    } catch (error) {
      //@ts-ignore
      const _description = error?.response?.data?.error?.message;

      notification.error({
        message: `Oops, ocorreu um erro ao deletar a conta Nº ${id}.`,
        description: _description,
        duration: 5,
      });
    }
  };

  const setFilterValue = (value) => {
    if (loading || loadingTotals) return;
    setSelectedFilter(value);
    setPaginate((oldValues) => ({ ...oldValues, page: 1 }));
    setShouldSearch(true);
  };

  const resetFilter = () => {
    setSelectedDate({
      initial_date: moment().startOf("month"),
      final_date: moment().endOf("month"),
    });
    setSelectedCategory(undefined);
    setShouldSearch(true);
  };

  const columns = [
    {
      title: (
        <ContentTileID>
          ID{" "}
          <Tooltip title="Identificador numérico sequencial">
            <InfoCircleIcon />
          </Tooltip>
        </ContentTileID>
      ),
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.id - b.id,
      defaultSortOrder: "descend" as any,
      render: (text, record) => {
        const borderColor = () => {
          const today = new Date();
          const dueDate = new Date(record.due_date);

          if (record.payment_status === true) {
            return "var(--green-400)";
          }

          if (!record.payment_status || record.payment_status === 2) {
            if (
              dueDate.getFullYear() === today.getFullYear() &&
              dueDate.getMonth() === today.getMonth() &&
              dueDate.getDate() === today.getDate()
            ) {
              return "var(--orange-600)";
            }
            if (dueDate < today) {
              return "var(--orange-600)";
            }
            return "black";
          }

          return "black";
        };

        return (
          <ContentBorderColor borderLeft={borderColor()}>
            <InfoCapitalized>{text}</InfoCapitalized>
          </ContentBorderColor>
        );
      },
    },
    {
      title: (
        <ContentTile>
          {window.innerWidth < 1800 ? "D. de Venc." : "Data de Vencimento"}
          <Tooltip title="Data de Vencimento da Conta">
            <InfoCircleIcon />
          </Tooltip>
        </ContentTile>
      ),
      dataIndex: "due_date",
      width: "13%",
      responsive: ["md"] as any,
      key: "due_date",
      render: (text) => (
        <InfoCapitalized>{moment(text).format("DD/MM/YYYY")}</InfoCapitalized>
      ),
    },
    {
      title: (
        <ContentTile>
          Categoria{" "}
          <Tooltip title="Categoria da Conta">
            <InfoCircleIcon />
          </Tooltip>
        </ContentTile>
      ),
      dataIndex: "product.name",
      key: "product.name",
      render: (text, record) => {
        const productName = record?.product?.name || (
          <ContentEmptyId>
            <MinusIcon />
          </ContentEmptyId>
        );
        const categoryName = record?.product?.category?.name || (
          <ContentEmptyId>
            <MinusIcon />
          </ContentEmptyId>
        );
        const chargeName = record?.product?.category?.name || (
          <ContentEmptyId>
            <MinusIcon />
          </ContentEmptyId>
        );

        if (record?.people_cost_items_id !== null) {
          return <InfoCapitalized>{chargeName}</InfoCapitalized>;
        }

        return (
          <InfoCapitalized>
            {record?.purchase_id ? categoryName : productName}
          </InfoCapitalized>
        );
      },
      sorter: (a, b) => {
        const nameA = a?.product?.name || "";
        const nameB = b?.product?.name || "";
        return nameA.localeCompare(nameB);
      },
    },
    {
      title: (
        <ContentTile>
          Fornecedor{" "}
          <Tooltip title="Fornecedor">
            <InfoCircleIcon />
          </Tooltip>
        </ContentTile>
      ),
      dataIndex: "provider",
      responsive: ["md"] as any,
      key: "provider",
      render: (text, record) => {
        const peopleCost = record?.people_cost_items_id;

        return (
          <Tooltip
            title={
              peopleCost !== null
                ? "Folha de Pagamento"
                : record?.provider?.fantasy_name || "Sem fornecedor"
            }
          >
            <InfoCapitalized>
              {peopleCost !== null
                ? "Folha de Pagamento"
                : record?.provider?.fantasy_name || "Sem fornecedor"}
            </InfoCapitalized>
          </Tooltip>
        );
      },
      sorter: (a, b) =>
        (a.provider?.fantasy_name || "").localeCompare(
          b.provider?.fantasy_name || ""
        ),
    },
    {
      title: (
        <ContentTile>
          Observação{" "}
          <Tooltip title="Observação da Conta">
            <InfoCircleIcon />
          </Tooltip>
        </ContentTile>
      ),
      dataIndex: "observation",
      responsive: ["md"] as any,
      key: "observation",
      render: (text) => (
        <Tooltip title={text}>
          <InfoCapitalized>{text ? text : "Sem Observação"}</InfoCapitalized>
        </Tooltip>
      ),
      sorter: (a, b) =>
        (a.observation || "").localeCompare(b.observation || ""),
    },
    {
      title: (
        <ContentTile>
          Valor{" "}
          <Tooltip title="Valor em reais da compra. O ícone ao lado indica o método de pagamento">
            <InfoCircleIcon />
          </Tooltip>
        </ContentTile>
      ),
      width: "15%",
      dataIndex: "Valor",
      key: "Valor",
      render: (text, record) => {
        const paymentMethod = record?.payment_method;

        const renderIcon = () => {
          switch (paymentMethod) {
            case PaymentType.Dinheiro:
              return <MoneyIcon />;
            case PaymentType.Transferência:
              return <TransferIcon />;
            case PaymentType.Boleto:
              return <BarcodeIcon />;
            case PaymentType.C_Crédito:
              return <CreditCardIcon />;
            case PaymentType.C_Débito:
              return <DebitCardIcon />;
            case PaymentType.Pix:
              return <PixIcon />;
            default:
              return <SelectMultipleIcon />;
          }
        };

        return (
          <Tooltip title={currencyFormater(+record?.payment_amount)}>
            <ContentTile>
              R$ {currencyFormater(+record?.payment_amount)} {renderIcon()}
            </ContentTile>
          </Tooltip>
        );
      },
      sorter: (a, b) => a.payment_amount - b.payment_amount,
    },
    {
      title: (
        <ContentTile>
          Data de Pgto{" "}
          <Tooltip title="Data em que o pagamento foi efetuado">
            <InfoCircleIcon />
          </Tooltip>
        </ContentTile>
      ),
      dataIndex: "payment_status",
      width: "13%",
      responsive: ["md"] as any,
      key: "payment_status",
      render: (_, record) => {
        return (
          <Tooltip
            title={
              record?.payment_date
                ? moment(record?.payment_date).format("DD/MM/YYYY HH:mm:ss")
                : "-"
            }
          >
            <InfoCapitalized>
              {record?.payment_date ? (
                moment(record?.payment_date).format("DD/MM/YYYY HH:mm:ss")
              ) : (
                <ContentEmptyId>
                  <MinusIcon />
                </ContentEmptyId>
              )}
            </InfoCapitalized>
          </Tooltip>
        );
      },
    },
    {
      title: (
        <ContentTile>
          Parcelas{" "}
          <Tooltip title="Parcelas Pagas / Total de Parcelas">
            <InfoCircleIcon />
          </Tooltip>
        </ContentTile>
      ),
      dataIndex: "payment_status",
      responsive: ["md"] as any,
      key: "payment_status",
      render: (_, record) => {
        return (
          <InfoCapitalized>
            {record?.installment_number
              ? `${record?.installment_number}/${
                  record?.purchase_installment
                    ? record?.purchase_installment
                    : "1"
                }`
              : `1/1`}
          </InfoCapitalized>
        );
      },
    },
    {
      title: (
        <ContentTile>
          ID de Origem{" "}
          <Tooltip title="ID original da compra/pedido/folha que gerou a conta">
            <InfoCircleIcon />
          </Tooltip>
        </ContentTile>
      ),
      dataIndex: "purchase_id",
      responsive: ["md"] as any,
      key: "purchase_id",
      render: (text, record) => {
        const peopleCost = record?.people_cost_items_id;

        return (
          <InfoCapitalized>
            {peopleCost
              ? peopleCost
              : text || (
                  <ContentEmptyId>
                    <MinusIcon />
                  </ContentEmptyId>
                )}
          </InfoCapitalized>
        );
      },
    },
    {
      title: "Ações",
      key: "action",
      render: (_, record) => (
        <Actions>
          <Dropdown
            overlay={
              <Menu>
                {!record?.payment_status && (
                  <Menu.Item
                    onClick={() => {
                      changeToStatusPaid(record?.id);
                    }}
                  >
                    <span>Dar baixa</span>
                  </Menu.Item>
                )}

                <Menu.Item>
                  {record?.purchase_id ? (
                    <Tooltip title="Esta parcela pertence a uma compra. Para excluí-la, vá para a tela de Compras e exclua a compra inteira.">
                      <DisabledSpan>Excluir conta</DisabledSpan>
                    </Tooltip>
                  ) : (
                    <span
                      onClick={() => deleteBill(record.id)}
                      style={{ color: "red", cursor: "pointer" }}
                    >
                      Excluir conta
                    </span>
                  )}
                </Menu.Item>
              </Menu>
            }
            trigger={["click"]}
            placement="bottomCenter"
            arrow
          >
            <MoreIcon />
          </Dropdown>
        </Actions>
      ),
    },
  ];

  const handleTableChange = (_page: number) => {
    setPaginate((oldValues) => ({ ...oldValues, page: _page }));
    setShouldSearch(true);
  };

  return (
    <PageContainer route="Contas a Pagar">
      <Container>
        {!store ? (
          <StoreSelectionPage
            title="Selecione uma loja para continuar"
            Img={ShopImg}
            store={store}
            setStore={setStore}
            setShouldSearch={setShouldSearch}
          />
        ) : (
          <>
            <DisabledFlag isStoreActive={isStoreActive} />
            <Row gutter={10}>
              <ContentHeader sm={12} xs={24}>
                <span>
                  Período: Data de Vencimento entre{" "}
                  {moment(selectedDate.initial_date).format("DD/MM/YYYY")} e{" "}
                  {moment(selectedDate.final_date).format("DD/MM/YYYY")}
                </span>
              </ContentHeader>
              <ContentHeader sm={12} xs={24}>
                <Row gutter={2}>
                  <Col sm={10} xs={10}>
                    <Input
                      placeholder="Buscar por fornecedor"
                      value={searchValue}
                      onChange={(e) => setSearchValue(e.target.value)}
                    />
                  </Col>
                  <Col sm={10} xs={10}>
                    <GetStore
                      defaultValue={store}
                      handleChange={(id) => {
                        setStore(id);
                        setShouldSearch(true);
                      }}
                    />
                  </Col>
                  <Col sm={2} xs={2}>
                    <Tooltip title={"Filtro"}>
                      <ButtonFilter onClick={() => setOpenFilterModal(true)}>
                        <FilterIcon />
                      </ButtonFilter>
                    </Tooltip>
                  </Col>
                  <Col sm={2} xs={2} style={{ padding: 0 }}>
                    <Tooltip title="Adicionar nova conta">
                      <StyledLink
                        to={{
                          pathname: "/new-bill-to-pay",
                          state: { store, accountsPayable, productsCategory },
                        }}
                      >
                        <AddIcon />
                      </StyledLink>
                    </Tooltip>
                  </Col>
                </Row>
              </ContentHeader>
            </Row>
            {store && (
              <>
                {loadingTotals ? (
                  <Centralizer>
                    <Spin />
                  </Centralizer>
                ) : (
                  <TableInfo>
                    <HeaderRow>
                      <Th
                        onClick={() => setFilterValue("overdue")}
                        isSelected={selectedFilter === "overdue"}
                        borderColor="var(--orange-600)"
                      >
                        Vencidos
                      </Th>
                      <Th
                        onClick={() => setFilterValue("today")}
                        isSelected={selectedFilter === "today"}
                        borderColor="var(--orange-600)"
                      >
                        Vencem Hoje
                      </Th>
                      <Th
                        onClick={() => setFilterValue("open")}
                        isSelected={selectedFilter === "open"}
                        borderColor="black"
                      >
                        Em Aberto
                      </Th>
                      <Th
                        onClick={() => setFilterValue("paid")}
                        isSelected={selectedFilter === "paid"}
                        borderColor="var(--green-400)"
                      >
                        Pagos
                      </Th>
                      <Th
                        onClick={() => setFilterValue(null)}
                        isSelected={selectedFilter === null}
                        borderColor="var(--orange-350)"
                      >
                        Total do Período
                      </Th>
                    </HeaderRow>
                    <BodyRow>
                      <StyledTd color="var(--orange-600)">
                        R$ {currencyFormater(+filterResults?.overdueAccount)}
                      </StyledTd>
                      <StyledTd color="var(--orange-600)">
                        R$ {currencyFormater(+filterResults?.today)}
                      </StyledTd>
                      <StyledTd>
                        R$ {currencyFormater(+filterResults?.openAccount)}
                      </StyledTd>
                      <StyledTd color="var(--green-400)">
                        R$ {currencyFormater(+filterResults?.paidAccount)}
                      </StyledTd>
                      <StyledTd color="var(--orange-350)">
                        R$ {currencyFormater(+filterResults?.totalOnPeriod)}
                      </StyledTd>
                    </BodyRow>
                  </TableInfo>
                )}
                {loading ? (
                  <Centralizer>
                    <Spin />
                  </Centralizer>
                ) : (
                  <>
                    {accountsPayable.length !== 0 ? (
                      <Table
                        columns={columns}
                        loading={loading}
                        pagination={{
                          current: page,
                          pageSize: size,
                          total: totalElements,
                          showSizeChanger: false,
                          onChange: handleTableChange,
                        }}
                        dataSource={filteredAccountsPayable.map((entity) => ({
                          ...entity,
                          key: entity.id,
                        }))}
                        onChange={(pagination) => {
                          setPaginate((prev) => ({
                            ...prev,
                            page: pagination.current || 1,
                          }));
                        }}
                      />
                    ) : (
                      <EmptyContent>
                        <Empty description="Nenhuma conta a pagar" />
                      </EmptyContent>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </Container>

      <Modal
        title={"Filtros"}
        centered
        visible={openFilterModal}
        closable={true}
        width={450}
        onCancel={() => setOpenFilterModal(false)}
        footer={[
          <ButtonCancel
            onClick={() => {
              setOpenFilterModal(false);
              resetFilter();
            }}
          >
            Limpar Filtro
          </ButtonCancel>,
          <ButtonSave
            loading={loading}
            onClick={() => {
              setShouldSearch(true);
              setOpenFilterModal(false);
              getAccountsPayable();
            }}
          >
            Buscar
          </ButtonSave>,
        ]}
      >
        <ContentModal>
          <Row>
            <Col xs={24}>
              <Form.Item label={"Data de Vencimento"}>
                <RangePickerElement
                  placeholder={["Data inicial", "Data final"]}
                  locale={locale}
                  format="DD/MM/YYYY"
                  value={
                    selectedDate.initial_date && selectedDate.final_date
                      ? [selectedDate.initial_date, selectedDate.final_date]
                      : null
                  }
                  onChange={(dates) => {
                    if (dates) {
                      setSelectedDate({
                        initial_date: dates[0],
                        final_date: dates[1],
                      });
                    } else {
                      setSelectedDate({
                        initial_date: null,
                        final_date: null,
                      });
                    }
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <Form.Item
                label={"Categoria"}
                style={{ flexDirection: "column", width: "100%" }}
              >
                <Select
                  showSearch
                  placeholder="Selecione uma categoria"
                  optionFilterProp="children"
                  value={selectedCategory}
                  onChange={(value) => setSelectedCategory(value)}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toString()
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                >
                  {productsCategory?.map((product) => (
                    <Select.Option
                      value={product.id!}
                      key={product.id}
                      label={product.name}
                    >
                      {product.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </ContentModal>
      </Modal>

      <PartialPaymentModal
        selectedBill={selectedBill}
        open={showPartialPaymentModal}
        setOpen={setShowPartialPaymentModal}
        setLoading={setLoading}
        setShouldSearch={setShouldSearch}
        loading={loading}
      />
    </PageContainer>
  );
};

export default BillsToPay;
