import React, { useState, useEffect } from "react";

import { withRouter, RouteComponentProps } from "react-router-dom";

import api from "../../../../services/api";
import apiMercury from "../../../../services/apiMercury";
import { verifyPermission } from "../../../../services/auth";
import { useStoreData } from "../../../../hooks/useStoreData";

import { Purchase } from "../../../../models/Purchase/Purchase";
import { PurchaseItems } from "../../../../models/Purchase/PurchaseItems";
import { ProductCategory as ProductCategoryResponse } from "../../../../models/ProductCategory";

import { removeAccentsAndLowerCase } from "../../../../utils/removeAccentsAndCaseSensitive";

import PageContainer from "../../../../containers/PageContainer";
import ShoppingList from "../../../../containers/ShoppingList";
import Centralizer from "../../../../containers/Centralizer";

import GetStore from "../../../../components/GetStore";
import MonetaryInput2 from "../../../../components/MonetaryInput2";
import RangeDataField from "../../../../components/RangeDataField";
import StoreSelectionPage from "../../../../components/StoreSelectionPage";
import DisabledFlag from "../../../../components/DisabledFlag";

import { Spin, Tooltip, message, notification } from "antd";

import moment, { Moment } from "moment";
import swal from "sweetalert";

import PurchasesImg from "../../../../assets/svg/purchasespage.svg";
import NoData from "../../../../assets/svg/nodatapurchase.svg";

import InfoPurchaseItemsDrawer from "../../../../containers/InfoPurchaseItems";

import {
  Row,
  Col,
  Checkbox,
  AddIcon,
  ButtonFilter,
  ButtonSearch,
  BodyList,
  FilterIcon,
  RowHeaderList,
  ColHeaderList,
  ModalFilter,
  ButtonClear,
  GraphIcon,
  Select,
  DownloadIcon,
  Input,
  LupaIcon,
  ColHeaderListButtons,
  StyledLink,
} from "./styles";
import { Page } from "../../../../models/Page";
import { exportCSVFile } from "../../../../services/exportCSVFile";

const { Option } = Select;

interface ComponentProps extends RouteComponentProps<{}, {}, any> { }

const Shop: React.FC<ComponentProps> = ({ location, history }) => {
  const { state } = location;

  //@ts-ignore
  const store_param = state?.store_param;

  const [productsCategory, setProductsCategory] = useState<
    ProductCategoryResponse[]
  >([]);

  const [purchasesOrder, setPurchasesOrder] = useState<Purchase | null>(
    null
  );
  const [purchasesItems, setPurchasesItems] = useState<PurchaseItems[]>([]);
  const [purchases, setPurchases] = useState<Purchase[]>([]);

  const [purchaseId, setPurchaseId] = useState<number>();
  const [store, setStore] = useState<number | null | undefined>(
    +store_param || null
  );

  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [allOrders, setAllOrders] = useState(false);
  const [check, setCheck] = useState(false);
  const [loadingInfo, setLoadingInfo] = useState(false);
  const [visibleInfo, setVisibleInfo] = useState(false);
  const [shouldSearch, setShouldSearch] = useState(false);
  const [visibleOrderBuy, setVisibleOrderBuy] = useState(false);
  const [purchaseFilters, setPurchaseFilters] = useState(false);

  const [searchValue, setSearchValue] = useState("");
  const [filteredProvider, setFilteredProvider] = useState<Purchase[]>([])

  const { isStoreActive } = useStoreData(store);

  const [paginate, setPaginate] = useState<Page>({
    page: 1,
    size: 10,
    totalElements: 0,
  });

  const { page, size, totalElements } = paginate;

  const [filter, setFilter] = useState<{
    data_inicial: Moment;
    data_final: Moment;
    value_min?: number;
    value_max?: number;
    category?: number;
    product?: number;
    status?: number;
  }>({
    data_inicial: moment(),
    data_final: moment().add(1, "days"),
    value_min: undefined,
    value_max: undefined,
    category: undefined,
    product: undefined,
    status: undefined,
  });

  const [date, setDate] = useState({
    data_inicial: moment().subtract(90, "days"),
    data_final: moment().add(1, "days"),
  });

  useEffect(() => {
    if (state?.storeId) {
      setStore(state.storeId);
      setFilter({
        data_inicial: moment(),
        data_final: moment().add(1, "days"),
        value_min: undefined,
        value_max: undefined,
        category: undefined,
        product: undefined,
        status: undefined,
      });
      setCheck(false);
    }
  }, [state]);

  useEffect(() => {
    const fetchShop = async () => {
      try {
        setLoading(true);
        const { data_inicial, data_final } = {
          data_inicial: moment(date.data_inicial).format("DD/MM/YYYY"),
          data_final: moment(date.data_final).format("DD/MM/YYYY"),
        };

        const UrlBuilder = () => {
          let URL = allOrders
            ? `purchases?purchaseFilters=${purchaseFilters}&page=${page}&size=${size}&data_inicial=${data_inicial}&data_final=${data_final}`
            : `purchases/store/${store}?purchaseFilters=${purchaseFilters}&data_inicial=${data_inicial}&data_final=${data_final}`;

          if (purchaseFilters) {
            if (filter.value_min || filter.value_max) {
              URL +=
                `&min_value=${filter.value_min ? filter.value_min : 0}` +
                `&max_value=${filter.value_max ? filter.value_max : 999999}`;
            }

            if (filter.category) {
              URL += `&category_id=${filter.category}`;
            }
            if (filter.status) {
              URL += `&status=${filter.status}`;
            }
          }
          return URL;
        };

        const {
          data: { content, totalElements },
        } = await apiMercury.get(UrlBuilder());

        if (content.length === 0 && purchaseFilters) {
          swal(
            "Nenhum resultado encontrado!",
            "Os valores de filtro não retornaram resultados",
            "warning"
          );
          setPurchaseFilters(false);
        } else {
          if (filter.product) {
            const productFilterList = content.filter((_purchase) =>
              _purchase.purchasesItems.some(
                (_product) => _product.product_id === filter.product
              )
            );
            if (productFilterList.length === 0) {
              swal(
                "Nenhum resultado encontrado!",
                "Os valores de filtro não retornaram resultados",
                "warning"
              );
              setPurchaseFilters(false);
            } else {
              setPurchases(productFilterList);
            }
          } else {
            setPurchases(content);
            setFilteredProvider(content);
          }
        }

        setPaginate((prev) => ({
          ...prev,
          totalElements,
        }));
        setLoading(false);
      } catch (e) {
        const _message =
          //@ts-ignore
          e?.response?.data?.message || "Houve um erro ao realizar busca";
        message.error(_message);
      }
    };
    if (((store || allOrders) && shouldSearch) || store_param) {
      fetchShop();
      setShouldSearch(false);
    }
  }, [
    store,
    shouldSearch,
    date.data_inicial,
    date.data_final,
    purchaseFilters,
    filter,
    store_param,
    page
  ]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  useEffect(() => {
    const filterProviders = () => {
      const filtered = purchases?.filter((purchase) =>
        purchase?.provider &&
        removeAccentsAndLowerCase(purchase.provider.company_name).includes(
          removeAccentsAndLowerCase(searchValue)
        )
      );
      setFilteredProvider(filtered || []);
    };

    if (searchValue) {
      filterProviders();
    } else {
      setFilteredProvider(purchases);
    }
  }, [searchValue, purchases]);


  useEffect(() => {
    const fetchProductsByCategory = async () => {
      try {
        const {
          data: { content },
        } = await api.get("/product_categories/products/purchases");
        setProductsCategory(content);
      } catch (e) {
        const _message =
          //@ts-ignore
          e?.response?.data?.message || "Houve um erro ao realizar busca";
        message.error(_message);
      }
    };
    fetchProductsByCategory();
  }, []);

  const searchFilter = () => {
    setAllOrders(check);
    setPurchaseFilters(true);
    setVisible(false);
    setShouldSearch(true);
  };

  const resetFilter = () => {
    setFilter({
      data_inicial: moment().subtract(90, "days"),
      data_final: moment().add(1, "days"),
      value_min: undefined,
      value_max: undefined,
      category: undefined,
      product: undefined,
      status: undefined,
    });
    setDate({
      data_inicial: moment().subtract(90, "days"),
      data_final: moment().add(1, "days"),
    });
    setAllOrders(false);
    setPurchaseFilters(false);
    setVisible(false);
    setShouldSearch(true);
  };

  const newPurchase = () => {
    setPurchaseId(undefined);
    setVisibleOrderBuy(true);
  };

  const infoModal = async (purchase) => {
    setVisibleInfo(true);
    setLoadingInfo(true);
    try {
      const {
        data: { content },
      } = await apiMercury.get(`/purchases/${purchase.id}`);
      setPurchasesOrder({
        ...content,
      });

      setPurchasesItems(
        content.purchasesItems.map((item) => ({
          id: +item.id,
          product_name: item.product.name,
          quantity: +item.quantity,
          unitary_value: item.unitary_value,
          category_id: item.category_id,
          observation: item?.observation,
          product_id: item.product_id,
          purchase_id: item.purchase_id,
          created_at: item?.created_at,
          deleted_at: item?.deleted_at,
          updated_at: item?.updated_at,
        }))
      );
    } catch (e) {
      const _message =
        //@ts-ignore
        e?.response?.data?.message || "Houve um erro ao realizar busca";
      message.error(_message);
    }
    setLoadingInfo(false);
  };

  const disabledDate = (current) => {
    const dates = [date.data_final, date.data_inicial];
    if (!dates || dates.length === 0) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], "days") > 90;
    const tooEarly = dates[1] && dates[1].diff(current, "days") > 90;
    return tooEarly || tooLate;
  };

  const addFirstPurchase = () => {
    const purchasesData = {
      purchasesItems,
      purchases,
      purchaseId,
      productsCategory,
      purchasesOrder,
      store,
    };
    history.push({
      pathname: '/new-shop',
      state: purchasesData
    });
  };

  const downloadCSV = () => {
    if (!purchases || purchases.length === 0) {
      notification.error({
        message: "Nenhum dado disponível para exportar.",
        duration: 5,
      });
      return;
    }

    const headers = {
      id: "ID da Compra",
      store_id: "ID da Loja",
      total: "Total da Compra",
      icms_st_value: "Total de ICMS ST",
      additional_value: "Total de Valor Adicional",
      discount_value: "Total de Desconto",
      purchase_items: "Qtde Itens",
      observation: removeAccentsAndLowerCase("Observação"),
      created_at: removeAccentsAndLowerCase("Data de Criação"),
    };

    const items = purchases.map((item) => ({
      id: item.id,
      store_id: store,
      total: item.total,
      icms_st_value: item.icms_st_value,
      additional_value: item.additional_value,
      discount_value: item.discount_value,
      purchase_items: item.purchasesItems.length,
      observation: removeAccentsAndLowerCase(item.observation),
      created_at: item?.created_at
    }));

    exportCSVFile(headers, items, `dados-compras-loja=${store}`);

    notification.success({
      message: "Arquivo CSV baixado com sucesso!",
      duration: 5,
    });
  };

  return (
    <PageContainer route="Compras">
      <>
        {(!store && allOrders) || (store && purchases.length !== 0) ? (
          <>
            <BodyList>
              <DisabledFlag isStoreActive={isStoreActive} />

              <RowHeaderList gutter={4}>
                <ColHeaderList sm={5} xs={24}>
                  <Input
                    placeholder="Buscar por fornecedor"
                    suffix={<LupaIcon />}
                    style={{
                      marginBottom: window.outerWidth < 768 ? '.5rem' : '0'
                    }}
                    onChange={handleSearchChange}
                  />
                </ColHeaderList>
                <ColHeaderList
                  sm={4}
                  xs={24}
                  style={{
                    marginBottom: window.outerWidth < 768 ? '.5rem' : '0'
                  }}>
                  <GetStore
                    defaultValue={store}
                    handleChange={(id) => {
                      setStore(id);
                      setFilter({
                        data_inicial: moment(),
                        data_final: moment().add(1, "days"),
                        value_min: undefined,
                        value_max: undefined,
                        category: undefined,
                        product: undefined,
                        status: undefined,
                      });
                      setShouldSearch(true);
                    }
                    }
                  />
                </ColHeaderList>

                <ColHeaderListButtons
                  sm={0}
                  md={!allOrders ? 1 : 0}
                  xs={0}
                  style={{ flex: "0" }}
                >
                  {verifyPermission("shop.purchases_report") && !allOrders && (
                    <Tooltip title={"Relatório de Compras"}>
                      <ButtonFilter
                        onClick={() => history.push(`/shop_report/${store}`)}
                      >
                        <GraphIcon />
                      </ButtonFilter>
                    </Tooltip>
                  )}
                </ColHeaderListButtons>

                <ColHeaderListButtons sm={1} xs={24}>
                  {(allOrders || store) && (
                    <Tooltip title={"Filtro"}>
                      <ButtonFilter
                        onClick={() => {
                          setVisible(true);
                          setCheck(allOrders);
                        }}
                      >
                        <FilterIcon />
                      </ButtonFilter>
                    </Tooltip>
                  )}
                </ColHeaderListButtons>

                <ColHeaderListButtons sm={1} xs={24}>
                  {(allOrders || store) && (
                    <Tooltip title={"Download CSV"}>
                      <ButtonFilter onClick={() => downloadCSV()}>
                        <DownloadIcon />
                      </ButtonFilter>
                    </Tooltip>
                  )}
                </ColHeaderListButtons>

                {!isStoreActive && (
                  <ColHeaderListButtons
                    sm={store ? 1 : 0}
                    xs={store ? 24 : 0}
                    style={{ marginRight: "0" }}
                  >
                    {verifyPermission("shop.add") && store && (
                      <Tooltip title={"Nova Compra"}>
                        <StyledLink
                          to={{
                            pathname: '/new-shop',
                            state: { purchasesItems, purchases, purchaseId, productsCategory, purchasesOrder, store }
                          }}
                        >
                          <AddIcon />
                        </StyledLink>
                      </Tooltip>
                    )}
                  </ColHeaderListButtons>
                )}

              </RowHeaderList>
              <RowHeaderList style={{ marginTop: "0.1rem" }}>
                {loading ? (
                  <BodyList style={{ marginTop: "15%" }}>
                    <Centralizer>
                      <Spin />
                    </Centralizer>
                  </BodyList>
                ) : (
                  <>
                    <ShoppingList
                      setLoading={setLoading}
                      shouldSearch={shouldSearch}
                      loading={loading}
                      setPurchaseId={setPurchaseId}
                      purchases={filteredProvider}
                      setShouldSearch={setShouldSearch}
                      buttonInfo={infoModal}
                      setVisibleOrderBuy={setVisibleOrderBuy}
                      isStoreActive={isStoreActive}
                      productsCategory={productsCategory}
                      store={store}
                      page={page}
                      size={size}
                      totalElements={totalElements}
                      setPaginate={setPaginate}
                    />
                  </>
                )}
              </RowHeaderList>
            </BodyList>
          </>
        ) : (
          <>
            {!store && !allOrders ? (
              <>
                <StoreSelectionPage
                  title="Para buscar ou cadastrar uma compra"
                  Img={PurchasesImg}
                  store={store}
                  setStore={setStore}
                  setShouldSearch={setShouldSearch}
                  checkBoxVisible={true}
                  checked={allOrders}
                  setAll={setAllOrders}
                  permissionCheck="shop.all_purchases"
                  textCheckBox="Todas as compras"
                ></StoreSelectionPage>
              </>
            ) : (
              <>
                {loading ? (
                  <BodyList>
                    <Centralizer>
                      <Spin />
                    </Centralizer>
                  </BodyList>
                ) : (
                  <>
                    <StoreSelectionPage
                      title="Oops! Nenhuma compra cadastrada nesta loja."
                      textTitle="Faça o cadastro de uma nova compra ou selecione outra loja"
                      Img={NoData}
                      store={store}
                      setStore={setStore}
                      setShouldSearch={setShouldSearch}
                      checkBoxVisible={true}
                      checked={allOrders}
                      setAll={setAllOrders}
                      permissionCheck="shop.all_purchases"
                      textCheckBox="Todas as compras"
                      buttonVisible={true}
                      permissionButton="shop.add"
                      buttonClick={addFirstPurchase}
                      buttonText="Nova Compra"
                    />
                  </>
                )}
              </>
            )}
          </>
        )}
      </>

      <ModalFilter
        title="Filtros"
        centered
        onCancel={() => setVisible(false)}
        visible={visible}
        width={450}
        footer={null}
        destroyOnClose={true}
      >
        <Row>
          <Col sm={24}>
            <span>Data de Vencimento</span>
            <RangeDataField
              data={date}
              setData={setDate}
              disabledDate={disabledDate}
            />
          </Col>

          <Col sm={12} xs={24}>
            <span>Valor Mínimo</span>
            <MonetaryInput2
              defaultValue={filter.value_min}
              getValue={(value) =>
                setFilter((oldValues) => ({ ...oldValues, value_min: +value }))
              }
            />
          </Col>
          <Col sm={12} xs={24}>
            <span>Valor Máximo</span>
            <MonetaryInput2
              defaultValue={filter.value_max}
              getValue={(value) =>
                setFilter((oldValues) => ({ ...oldValues, value_max: +value }))
              }
            />
          </Col>

          {verifyPermission("shop.all_purchases") && (
            <Col sm={24}>
              <Checkbox
                checked={check}
                onChange={() => {
                  setCheck(!check);
                }}
              >
                <span className="allOrders">
                  Todas <span className="orders">as compras</span>
                </span>
              </Checkbox>
            </Col>
          )}
          <Col sm={16} xs={24}>
            <ButtonSearch onClick={() => searchFilter()}>
              Buscar itens
            </ButtonSearch>
          </Col>
          <Col sm={8} xs={24}>
            <ButtonClear onClick={() => resetFilter()}>
              <span>Limpar Filtro</span>
            </ButtonClear>
          </Col>
        </Row>
      </ModalFilter>

      <InfoPurchaseItemsDrawer
        visible={visibleInfo}
        setVisible={setVisibleInfo}
        purchasesOrder={purchasesOrder}
        purchases={purchases}
        productsCategory={productsCategory}
        loadingInfo={loadingInfo}
      />

    </PageContainer>
  );
};

export default withRouter(Shop);
