import styled, { css } from 'styled-components';
import { ChevronLeft, InfoCircle, TrashFeather, TrashIconA } from '../../../styles/Icons';
import {
  Button as ButtonAnt,
  Select as SelectAnt,
  Input as InputAnt,
  DatePicker as DatePickerAnt,
  Col as ColAnt,
} from 'antd'

const Icon = css`
    width: 1rem;
    height: 1rem;
`;

export const ChevronIcon = styled(ChevronLeft)`
    color: black;
    cursor: pointer;
    width: 1.2rem;
    height: 2.2rem;
`;

export const Validate = styled.span`
  color: red;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  height: 100%;
`;

export const Capitalize = styled.span`
  text-transform: capitalize;
`;

export const Footer = styled.footer`
    display: flex;
    align-items: center;
    justify-content: start;
`;

export const Input = styled(InputAnt)`
  width: 100%;
  height: 2.5rem !important;
  border-radius: 5px !important;

  .ant-input {
    height: 1.9rem !important;
  }
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;

  @media only screen and (max-width: 578px) {
    width: 100%;
    overflow-y: scroll;
  }
`;

export const TrashIcon = styled(TrashFeather)`
    ${Icon}
    width: 1.5rem;
    height: 1.5rem;
    color: var(--maroon);
    cursor: pointer;
`;

export const DatePicker = styled(DatePickerAnt)`
  width: 100%;
  height: 2.5rem !important;

  .ant-picker-input > input {
    font-weight: 400 !important;
    font-size: .9rem !important;
  }
`;

export const NewRowButton = styled(ButtonAnt)`
    display: flex;
    align-items: start;
    justify-content: start;

    margin-top: -1rem;

    background-color: transparent;
    border: none;
    font-size: .9rem;
    box-shadow: none;

    color: var(--gray-170);
    font-weight: 500;

    padding: 0;
    margin: 0;
`;

export const ColIcon = styled(ColAnt)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.span`
    color: var(--gray-45);
    font-size: 1.2rem;
    text-transform: capitalize;
    margin-top: 3rem;
`;

export const InfoCircleIcon = styled(InfoCircle)`
  width: 15px;
  height: 15px;
  margin-left: 5px;

  cursor: pointer;
  color: var(--c64-blue);
`;

export const Select = styled(SelectAnt)`
  width: 100%;
  height: 2.5rem !important;

  .ant-select-selection-item {
    display: flex;
    align-items: center;
  }

  .ant-select-selector {
    height: 2.5rem !important;
  }
`;
interface IPropsButton {
  color: string;
  backgroundColor: string;
  border: string;
  borderColor?: string;
}
export const Button = styled(ButtonAnt) <IPropsButton>`
      color: ${(props) => props.color};
      background-color: ${(props) => props.backgroundColor};
      display: flex;
      align-items: center;
      justify-content: center;
  
      width: 15rem;
      border: ${(props) => props.border};
      border-color: ${(props) => props.borderColor};
  
      padding: 1.5rem;
      border-radius: 5px;
  
      :first-child {
          margin-right: 1rem;
      }
  
      :hover, :focus {
          color: ${(props) => props.color};
          background-color: ${(props) => props.backgroundColor};
          opacity: 80%;
      }
  `;

export const ColAnexo = styled(ColAnt)`
`;

export const ContentFiles = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TrashIconUpload = styled(TrashIconA)`
    ${Icon}
    width: 1rem;
    height: 1rem;
    color: var(--maroon);
    cursor: pointer;
`;
