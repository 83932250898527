import styled, { css } from "styled-components";

import {
  Row as RowAnt,
  Col as ColAnt,
  Form as FormAnt,
  Input as InputAnt,
  DatePicker as DatePickerAnt,
  Select as SelectAnt,
  Button as ButtonAnt,
} from "antd";
import {
  InfoCircle,
  TrashIconA,
  TrashFeather,
  FiletypePdf,
  ChevronLeft,
} from "../../../../styles/Icons";

const Icon = css`
  width: 1rem;
  height: 1rem;
`;

export const TrashIcon = styled(TrashFeather)`
  ${Icon}
  width: 1.5rem;
  height: 1.5rem;
  color: var(--maroon);
  cursor: pointer;
`;

export const InputFile = styled.input`
  display: none !important;
`;

export const StyledInput = styled.label`
  display: inline-block;
  padding: 10px 20px;
  background-color: transparent;
  color: var(--orange-350);
  border: 1px solid var(--orange-350);
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
`;

export const TrashIconUpload = styled(TrashIconA)`
  ${Icon}
  width: 1rem;
  height: 1rem;
  color: var(--maroon);
  cursor: pointer;
`;

export const Container = styled.div`
  width: 100%;
  overflow: hidden;

  display: flex;
  flex-direction: column;
`;

export const Row = styled(RowAnt)`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const Input = styled(InputAnt)`
  width: 100%;
  height: 2.5rem !important;
  border-radius: 5px !important;

  .ant-input {
    height: 1.9rem !important;
  }
`;

export const Select = styled(SelectAnt)`
  width: 100%;
  height: 2.5rem !important;

  .ant-select-selection-item {
    display: flex;
    align-items: center;
  }

  .ant-select-selector {
    height: 2.5rem !important;
  }
`;

export const Col = styled(ColAnt)`
  height: 5.1rem;

  @media screen and (max-width: 768px) {
    height: 100%;
  }
`;

export const ColIcon = styled(ColAnt)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Form = styled(FormAnt)`
  width: 100%;

  .ant-form-item-label > label {
    font-weight: 400 !important;
  }
`;

export const ContentFileView = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`;

export const ChevronIcon = styled(ChevronLeft)`
  color: black;
  cursor: pointer;
  width: 1.2rem;
  height: 2.2rem;
`;

export const TitlePage = styled.span`
  font-size: 1.1rem;
  color: var(--gray-650);
  font-weight: 500;

  margin: 1rem 0 0.2rem;
`;

export const DatePicker = styled(DatePickerAnt)`
  width: 100%;
  height: 2.5rem !important;
`;

export const Footer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 1rem 0;
`;

interface IPropsButton {
  color: string;
  backgroundColor: string;
  border: string;
  borderColor?: string;
}
export const Button = styled(ButtonAnt) <IPropsButton>`
  color: ${(props) => props.color};
  background-color: ${(props) => props.backgroundColor};
  display: flex;
  align-items: center;
  justify-content: center;

  width: 15rem;
  border: ${(props) => props.border};
  border-color: ${(props) => props.borderColor};

  padding: 1.5rem;
  border-radius: 5px;

  :first-child {
    margin-right: 1rem;
  }

  :hover,
  :focus {
    color: ${(props) => props.color};
    background-color: ${(props) => props.backgroundColor};
    opacity: 80%;
  }
`;

export const NewRowButton = styled(ButtonAnt)`
  display: flex;
  align-items: start;
  justify-content: start;

  background-color: transparent;
  border: none;
  font-size: 0.9rem;
  box-shadow: none;

  color: var(--gray-170);
  font-weight: 500;

  padding: 0;
  margin: 0;
`;

export const ContentPDF = styled.div`
  display: flex;
  align-items: center;

  a {
    margin-left: 0.8rem;
    color: var(--gray-600);
    font-size: 0.9rem;
  }
`;

export const ContentImage = styled.img`
  width: 10rem;
  height: 10rem;
  margin: 0.5rem;
`;

export const InfoCircleIcon = styled(InfoCircle)`
  width: 15px;
  height: 15px;
  margin-left: 5px;

  cursor: pointer;
  color: var(--c64-blue);
`;

export const FiletypePdfIcon = styled(FiletypePdf)`
  width: 25px;
  height: 25px;

  cursor: pointer;
  color: var(--gray-200);
`;

export const UploadWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2%;

  input[type="file"]::file-selector-button {
    border: none;
    background: var(--orange-gradient);
    padding: 10px 20px;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    width: 100%;
  }
`;
