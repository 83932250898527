import styled from "styled-components";
import { FilterAlt, LineGraph, Download, AddCircleOutline, MagnifyingGlass } from "../../../../styles/Icons";
import {
  Button,
  Col as ColAnt,
  Row as RowAnt,
  Checkbox as CheckboxAnt,
  Modal as ModalAnt,
  Table as TableAnt,
  Input as InputAnt,
  Select as SelectAnt,
} from "antd";
import { Link } from "react-router-dom";

export const Checkbox = styled(CheckboxAnt)`
  font-size: 10px;
  zoom: 1.5;
  margin: 10px 0 5px;
  font-weight: 600;
`;

export const BodyList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .ant-table-container {
    margin-top: 0.2rem;
    border-radius: 0;
  }
`;

export const Icon = styled.div`
  width: 1.2rem;
  height: 1.2rem;
`;

export const AddIcon = styled(AddCircleOutline)`
  width: 1.3rem;
  height: 1.3rem;
`;

export const LupaIcon = styled(MagnifyingGlass)`
  width: 1.3rem;
  height: 1.3rem;
  color: var(--orange-350);
`;

export const FilterIcon = styled(FilterAlt)`
  width: 1.3rem;
  height: 1.3rem;
`;

export const DownloadIcon = styled(Download)`
  width: 1.3rem;
  height: 1.3rem;
`

export const GraphIcon = styled(LineGraph)`
  ${Icon}
`;

export const Row = styled(RowAnt)`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const Col = styled(ColAnt)``;

export const RowHeaderList = styled(RowAnt)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin: 0.1rem 0 0.5rem;

  @media screen and (max-width: 578px) {
    justify-content: space-between;
  }
`;

export const ColHeaderListButtons = styled(ColAnt)`
  margin-right: 0.3rem;
  min-width: 3.25rem;

  @media screen and (min-width: 768px) {
    flex: 0;
    width: 100%;
  }
`;

export const ColHeaderList = styled(ColAnt)`
  margin-right: 0.3rem;
  min-width: 3.25rem;

  @media only screen and (max-width: 1400px) {
    .allOrders {
      font-size: 0.8rem;
    }
  }
  @media only screen and (max-width: 700px) {
    .allOrders {
      font-size: 0.7rem;
    }
  }
  @media only screen and (max-width: 500px) {
    .allOrders {
      font-size: 0.5rem;
    }
    .orders {
      display: none;
    }
  }
`;

const commonButtonStyles = `
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 3.3rem;
  width: 3.3rem;
  border-radius: 5px;
  color: var(--white);

  :hover {
    opacity: 80%;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    margin-bottom: .5rem;
  }
`;

export const ButtonFilter = styled(Button)`
  ${commonButtonStyles}
  background: var(--orange-350);

  :hover, :focus {
    background: var(--orange-350);
    color: var(--white);
  }
`;

const commonModalStyles = `
  padding: 3rem;

  .ant-modal-title {
    color: var(--gray-45);
    font-weight: 500;
    font-size: 2rem;
  }
  .ant-modal-body {
    padding: 0 3rem 3rem;
  }
  .ant-row {
    display: flex;
    align-items: center;
    min-height: 3.25rem;
    color: var(--blue-999);
    font-weight: 400;
    text-transform: capitalize;
  }
`;

export const TableInfo = styled(TableAnt)`
  height: 100%;
`;

export const ModalFilter = styled(ModalAnt)`
  .ant-modal-body {
    border-top: 1px solid var(--gray-75);

    .ant-select-selection-item,
    .ant-select-selection-placeholder {
      align-self: center;
    }

    .info {
      color: var(--gray-45);
    }

    span {
      font-weight: 500;
    }

    .ant-input,
    .ant-input-password,
    .ant-select-selector,
    .ant-picker {
      height: 3.25rem;
      outline: none;
      box-shadow: none;
      background: var(--white);

      :focus,
      :active,
      :hover {
        outline: none;
        box-shadow: none;
      }
    }

    .ant-col {
      padding: 0.7rem;
    }
  }
`;

export const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--orange-gradient);
  color: var(--white);
  height: 3.3rem;
  width: 3.3rem;
  border-radius: 5px;
  text-decoration: none; 
  :hover {
    color: var(--white);
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const Input = styled(InputAnt)`
  border-radius: 6px;
  width: 100%;
  height: 3.2rem;
  
  .ant-input {
    height: 40px;
  }
`;

const commonLargeButtonStyles = `
  width: 100%;
  height: 3.25rem;
  font-weight: 600;
  border-radius: 4px;

  :hover, :focus, :active {
    opacity: 80%;
  }
`;

export const ButtonSearch = styled(Button)`
  ${commonLargeButtonStyles}
  border: none;
  background: var(--orange-350);
  color: var(--white);

  :hover,
  :focus,
  :active {
    background: var(--orange-350);
    color: var(--white);
  }
`;

export const ButtonClear = styled(Button)`
  ${commonLargeButtonStyles}
  border: 1px solid var(--gray-45);
  color: var(--gray-45);
  background: var(--white);

  :hover,
  :focus,
  :active {
    border: 1px solid var(--gray-45);
    color: var(--gray-45);
    background: var(--white);
  }
`;

export const Select = styled(SelectAnt)`
  width: 100%;
  text-transform: capitalize;
`;