import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import moment, { Moment } from "moment";
import {
  Dropdown,
  Empty,
  Menu,
  Modal,
  notification,
  Select,
  Spin,
  Tooltip,
} from "antd";
import locale from "antd/es/date-picker/locale/pt_BR";

import PageContainer from "../../containers/PageContainer";
import Centralizer from "../../containers/Centralizer";
import StoreSelectionPage from "../../components/StoreSelectionPage";
import DisabledFlag from "../../components/DisabledFlag";
import GetStore from "../../components/GetStore";

import { PeopleAccounts } from "../../models/People/PeopleAccounts";
import { Employees } from "../../models/People/Employees";
import { ProductCategory } from "../../models/ProductCategory";
import { Page } from "../../models/Page";
import ModalDetails from "./ModalDetails";

import { useStoreData } from "../../hooks/useStoreData";
import { currencyFormater } from "../../services/currencyFormater";
import apiMercury from "../../services/apiMercury";
import api from "../../services/apiAuth";
import apiOdin from "../../services/api";
import { exportCSVFile } from "../../services/exportCSVFile";

import { removeAccentsAndLowerCase } from "../../utils/removeAccentsAndCaseSensitive";

import PeopleScreen from "../../assets/svg/peopleScreen.svg";

import {
  Actions,
  Container,
  ContentTile,
  EmptyContent,
  TotalText,
  InfoCapitalized,
  InfoCircleIcon,
  Col,
  MoreIcon,
  Table,
  TopScreenMenu,
  ButtonCommon,
  DatePicker,
  DownloadIcon,
  AddIcon,
  StyledLink,
  TopRow,
} from "./styles";

interface LocationState {
  store_param?: number;
  shouldSearch?: boolean;
  data_inicial?: string;
  data_final?: string;
}

const People = () => {
  const [peopleInfo, setPeopleInfo] = useState<PeopleAccounts[]>([]);
  const [selectedPeopleInfo, setSelectedPeopleInfo] = useState<
    PeopleAccounts | undefined
  >(undefined);
  const [selectedPeopleCostItems, setSelectedPeopleCostItems] = useState<
    PeopleAccounts[]
  >([]);

  const [employees, setEmployees] = useState<Employees[]>([]);
  const [selectedEmployee, setSelectedEmployee] = useState<string | undefined>(
    undefined
  );

  const [productsCategory, setProductsCategory] = useState<ProductCategory[]>(
    []
  );

  const [store, setStore] = useState<number | undefined | null>();
  const [showDatailsModal, setShowDatailsModal] = useState<boolean>(false);
  const [month, setMonth] = useState<string>("");
  const [shouldSearch, setShouldSearch] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingCopy, setLoadingCopy] = useState<boolean>(false);

  const [selectedDate, setSelectedDate] = useState<{
    initial_date: Moment | null;
    final_date: Moment | null;
  }>({
    initial_date: null,
    final_date: null,
  });

  const [paginate, setPaginate] = useState<Page>({
    page: 1,
    size: 10,
    totalElements: 0,
  });

  const location = useLocation<LocationState>();
  const { isStoreActive } = useStoreData(store);
  const { page, size, totalElements } = paginate;

  useEffect(() => {
    const _month = moment(selectedDate.initial_date).toISOString();
    setMonth(() => _month);
  }, [selectedDate]);

  useEffect(() => {
    if (location.state?.store_param) {
      setStore(location.state.store_param);
    }

    if (location.state?.data_inicial && location.state?.data_final) {
      setSelectedDate({
        initial_date: moment(location.state.data_inicial, "DD/MM/YYYY"),
        final_date: moment(location.state.data_final, "DD/MM/YYYY"),
      });
    }

    if (location.state?.shouldSearch) {
      setShouldSearch(true);
    }
  }, [location.state]);

  useEffect(() => {
    const getPeopleInfo = async () => {
      if (!store || !selectedDate.initial_date) {
        return;
      }

      const initialDate =
        selectedDate.initial_date || moment().startOf("month");
      const finalDate = selectedDate.final_date || moment().endOf("month");

      const formattedInitialDate = initialDate.format("01/MM/YYYY");
      const formattedFinalDate = finalDate.format("DD/MM/YYYY");

      let URL = `/people-cost?page=${page}&size=${size}&store_id=${store}&data_inicial=${formattedInitialDate}&data_final=${formattedFinalDate}`;

      if (selectedEmployee) {
        URL += `&name=${selectedEmployee}`;
      }
      setLoading(true);
      try {
        const {
          data: { content, totalElements },
        } = await apiMercury.get(URL);
        setPeopleInfo(content);
        setPaginate((oldValues) => ({ ...oldValues, totalElements }));
      } catch (error) {
        //@ts-ignore
        const message = error?.response?.data?.message;

        notification.error({
          message: message || "Erro ao buscar as informações",
          duration: 5,
        });
      } finally {
        setShouldSearch(false);
        setLoading(false);
      }
    };

    if (store && selectedDate && shouldSearch) {
      getPeopleInfo();
    }
  }, [store, selectedDate, page, shouldSearch, selectedEmployee]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (store) {
          const {
            data: { content },
          } = await api.get(`/companyUser/${store}/company`);
          setEmployees(content);
        }

        const {
          data: { data: categories },
        } = await apiOdin.get(`/product_categories`);
        const encargosCategory = categories.find(
          (item) => item.name === "custo com pessoas"
        )?.id;

        if (encargosCategory) {
          const {
            data: { data: products },
          } = await apiOdin.get(
            `/product_categories/product/categories?category_id=${encargosCategory}`
          );
          setProductsCategory(products);
        }
      } catch (error) {
        const message =
          //@ts-ignore
          error?.response?.data?.message ||
          //@ts-ignore
          error?.response?.data?.error?.message;

        notification.error({
          message: "Erro ao buscar informações",
          description: message || "Erro ao buscar dados.",
          duration: 5,
        });
      }
    };

    if (store && selectedDate.initial_date) {
      fetchData();
    }
  }, [store, selectedDate]);

  const downloadCSV = () => {
    if (!peopleInfo || peopleInfo.length === 0) {
      notification.error({
        message: "Nenhum dado disponível para exportar.",
        duration: 5,
      });
      return;
    }

    const headers = {
      id: "ID",
      name: "Nome",
      store_id: "Loja",
      total_value: "Total",
      month_competence: removeAccentsAndLowerCase("Competência"),
      url_file: "URL",
      observation: removeAccentsAndLowerCase("Observação"),
    };

    const items = peopleInfo.map((person) => ({
      id: person.id,
      name: removeAccentsAndLowerCase(person.name),
      store_id: person.store_id,
      total_value: person.total_value,
      month_competence: person.month_competence,
      url_file: person.url_file,
      observation: removeAccentsAndLowerCase(person.observation),
    }));

    exportCSVFile(headers, items, "people-data");

    notification.success({
      message: "Arquivo CSV baixado com sucesso!",
      duration: 5,
    });
  };

  const deleteCost = async (id: number) => {
    Modal.confirm({
      title: "Deletar custo?",
      content: "Tem certeza que gostaria de deletar esse custo?",
      okText: "Sim",
      okType: "danger",
      cancelText: "Não",
      centered: true,
      async onOk() {
        try {
          setLoading(true);
          await apiMercury.delete(`/people-cost/${id}`);

          notification.success({
            message: "Custo deletado com sucesso",
            duration: 5,
          });
          setShouldSearch(true);
        } catch (error) {
          const _message = "Erro ao deletar custo";

          //@ts-ignore
          const _description = error?.response?.data?.error?.message;

          notification.error({
            message: _message,
            description: _description,
            duration: 5,
          });
        } finally {
          setLoading(false);
        }
      },
    });
  };

  const handleCopy = async (record) => {
    if (!record || !record.month_competence) {
      console.error("Record inválido:", record);
      notification.error({
        message: "Dados inválidos para copiar!",
        duration: 5,
      });
      return;
    }

    try {
      const nextMonth = moment(record.month_competence)
        .add(1, "month")
        .startOf("month");
      const formattedDate = nextMonth.toISOString();

      const payload = {
        id: record.id,
        category_id: record.category_id,
        name: record.name,
        store_id: record.store_id,
        created_at: moment().toISOString(),
        total_value: record.total_value
          ? Number(record.total_value).toFixed(2)
          : null,
        month_competence: formattedDate,
        url_file: record.url_file || "",
        s3_key: record.s3_key || "",
        observation: record.observation || "",
        peopleCostItems:
          record.peopleCostItems?.map((item) => ({
            payment_amount: item?.payment_amount || 0,
            product_id: item?.product_id,
            payment_method: item?.payment_method,
            payment_date: item?.payment_date
              ? moment(item.payment_date).add(1, "month").toISOString()
              : null,
            additional_value: item?.additional_value || 0,
            discounts: item?.discounts || 0,
            create_accounts_payments: item?.create_accounts_payments || true,
          })) || [],
      };

      setLoadingCopy(true);
      await apiMercury.post(`/people-cost`, payload);

      notification.success({
        message: "Folha de pagamento copiada para o mês seguinte com sucesso!",
        duration: 5,
      });
    } catch (error) {
      notification.error({
        message: "Oops, ocorreu um erro ao copiar os dados!",
        duration: 5,
      });
    } finally {
      setLoadingCopy(false);
    }
  };

  const columns = [
    {
      title: (
        <ContentTile>
          ID{" "}
          <Tooltip title="Identificador numérico sequencial">
            <InfoCircleIcon />
          </Tooltip>
        </ContentTile>
      ),
      dataIndex: "id",
      sorter: (a, b) => a.id - b.id,
      key: "id",
      render: (text) => <InfoCapitalized>{text}</InfoCapitalized>,
      defaultSortOrder: "descend" as any,
    },
    {
      title: (
        <ContentTile>
          Nome{" "}
          <Tooltip title="Nome do Colaborador">
            <InfoCircleIcon />
          </Tooltip>
        </ContentTile>
      ),
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text) => <InfoCapitalized>{text}</InfoCapitalized>,
    },
    {
      title: (
        <ContentTile>
          Encargo{" "}
          <Tooltip title="Quantidade de custos declarados">
            <InfoCircleIcon />
          </Tooltip>
        </ContentTile>
      ),
      dataIndex: "peopleCostItems",
      key: "peopleCostItems",
      responsive: ["md"] as any,
      render: (peopleCostItems) => {
        const itemCount = peopleCostItems.length;
        const itemText = itemCount === 1 ? "item" : "itens";

        return <span>{`${itemCount} ${itemText}`}</span>;
      },
    },
    {
      title: (
        <ContentTile>
          Observação{" "}
          <Tooltip title="Comentário sobre a folha">
            <InfoCircleIcon />
          </Tooltip>
        </ContentTile>
      ),
      dataIndex: "observation",
      key: "observation",
      responsive: ["md"] as any,
      render: (text) => (
        <InfoCapitalized>{text ? text : "Sem Observação"}</InfoCapitalized>
      ),
    },
    {
      title: (
        <ContentTile>
          Data de Criação{" "}
          <Tooltip title="Data que os encargos foram lançados no sistema">
            <InfoCircleIcon />
          </Tooltip>
        </ContentTile>
      ),
      responsive: ["md"] as any,
      dataIndex: "created_at",
      key: "created_at",
      render: (_, record) => (
        <InfoCapitalized>
          {moment(record.created_at).format("DD/MM/YYYY HH:mm:ss")}
        </InfoCapitalized>
      ),
    },
    {
      title: (
        <ContentTile>
          Valor Total{" "}
          <Tooltip title="Valor total dos encargos. O ícone ao lado indica o método de pagamento">
            <InfoCircleIcon />
          </Tooltip>
        </ContentTile>
      ),
      dataIndex: "total_value",
      key: "total_value",
      render: (peopleCostItems) => {
        return (
          <ContentTile>R$ {currencyFormater(+peopleCostItems)}</ContentTile>
        );
      },
    },
    {
      title: "Ações",
      key: "action",
      render: (_, record) => (
        <Actions>
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item
                  onClick={() => {
                    setShowDatailsModal(true);
                    setSelectedPeopleCostItems(record.peopleCostItems);
                    setSelectedPeopleInfo(record);
                  }}
                >
                  <span>Ver Detalhes</span>
                </Menu.Item>
                <Menu.Item
                  onClick={() => handleCopy(record)}
                  disabled={loadingCopy}
                >
                  <span>
                    {loading
                      ? "Copiando..."
                      : "Copiar Encargos P/ Mês Seguinte"}
                  </span>
                </Menu.Item>
                <Menu.Item onClick={() => deleteCost(record?.id)}>
                  <span style={{ color: "red" }}>Apagar Custo</span>
                </Menu.Item>
              </Menu>
            }
            trigger={["click"]}
            placement="bottomLeft"
            arrow
          >
            <MoreIcon />
          </Dropdown>
        </Actions>
      ),
    },
  ];

  const handleTableChange = (_page: number) => {
    setPaginate((oldValues) => ({ ...oldValues, page: _page }));
    setShouldSearch(true);
  };

  const totalPayments = peopleInfo.reduce(
    (acc, total) => acc + +total.total_value,
    0
  );

  return (
    <PageContainer route="Folha de Pagamento">
      {loading ? (
        <Centralizer>
          <Spin />
        </Centralizer>
      ) : (
        <Container>
          {!store ? (
            <StoreSelectionPage
              title="Veja os custos com pessoas por loja!"
              Img={PeopleScreen}
              store={store}
              setStore={setStore}
              setShouldSearch={setShouldSearch}
              date={true}
              setSelectedDate={setSelectedDate}
              selectedDate={selectedDate}
            />
          ) : loading ? (
            <Centralizer>
              <Spin />
            </Centralizer>
          ) : (
            <>
              <DisabledFlag isStoreActive={isStoreActive} />

              <TopScreenMenu>
                <TopRow gutter={6}>
                  <Col lg={5} md={4} xs={24}>
                    <TotalText>
                      <span>Total: </span>
                      <span className="total">
                        R${currencyFormater(+totalPayments)}
                      </span>
                    </TotalText>
                  </Col>

                  <div>
                    <Col lg={6} xs={24}>
                      <GetStore
                        defaultValue={store}
                        handleChange={(id) => {
                          setStore(id);
                          setShouldSearch(true);
                        }}
                      />
                    </Col>
                    <Col lg={6} xs={24}>
                      <DatePicker
                        picker="month"
                        locale={locale}
                        format="MMMM/YYYY"
                        value={
                          selectedDate.initial_date || moment().startOf("month")
                        }
                        onChange={(date) => {
                          if (date) {
                            setSelectedDate({
                              initial_date: date.startOf("month"),
                              final_date: date.endOf("month"),
                            });

                            setPaginate({
                              page: 1,
                              size: 10,
                              totalElements,
                            });
                          } else {
                            setSelectedDate({
                              initial_date: null,
                              final_date: null,
                            });
                          }

                          setShouldSearch(true);
                        }}
                        placeholder="Selecione Mês/Ano"
                        style={{ width: "100%" }}
                      />
                    </Col>

                    <Col lg={6} xs={24}>
                      <Select
                        showSearch
                        placeholder="Filtrar por nome do funcionário"
                        loading={loading}
                        onChange={(e: string | undefined) => {
                          setSelectedEmployee(e);
                          setPaginate((prev) => ({ ...prev, page: 1 }));
                          setShouldSearch(true);
                        }}
                        value={selectedEmployee}
                        style={{ width: "100%" }}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option?.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        allowClear
                        onClear={() => {
                          setSelectedEmployee(undefined);
                          setShouldSearch(true);
                          setPaginate((prev) => ({ ...prev, page: 1 }));
                        }}
                      >
                        {employees.map((employee) => (
                          <Select.Option
                            value={employee.name}
                            key={employee.user_id}
                            label={employee.name}
                          >
                            {employee.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Col>
                  </div>

                  <Col lg={1} md={1} xs={24}>
                    <Tooltip title="Download de CSV">
                      <ButtonCommon onClick={downloadCSV}>
                        <DownloadIcon />
                      </ButtonCommon>
                    </Tooltip>
                  </Col>
                  <Col lg={1} md={1} xs={24} className="first-col">
                    <Tooltip title="Adicionar nova conta">
                      <StyledLink
                        to={{
                          pathname: "/new-payment",
                          state: {
                            store,
                            employees,
                            productsCategory,
                            month,
                          },
                        }}
                      >
                        <AddIcon />
                      </StyledLink>
                    </Tooltip>
                  </Col>
                </TopRow>
              </TopScreenMenu>

              {peopleInfo.length !== 0 ? (
                <>
                  <Table
                    columns={columns}
                    loading={loading}
                    dataSource={peopleInfo.map((entity) => ({
                      ...entity,
                      key: entity.id,
                    }))}
                    pagination={{
                      current: page,
                      pageSize: size,
                      total: totalElements,
                      showSizeChanger: false,
                      onChange: handleTableChange,
                    }}
                    onChange={(pagination) => {
                      setPaginate((prev) => ({
                        ...prev,
                        page: pagination.current || 1,
                      }));
                    }}
                  />
                </>
              ) : (
                <EmptyContent>
                  <Empty description="Nenhuma informação encontrada" />
                </EmptyContent>
              )}
            </>
          )}
        </Container>
      )}

      <ModalDetails
        visible={showDatailsModal}
        setVisible={setShowDatailsModal}
        peopleInfo={selectedPeopleCostItems}
        selectedPeopleInfo={selectedPeopleInfo}
      />
    </PageContainer>
  );
};

export default People;
