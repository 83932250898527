import React, { Dispatch, SetStateAction, useState } from "react";

import { verifyPermission } from "../../services/auth";

import { Moment } from "moment";

import locale from 'antd/es/date-picker/locale/pt_BR';

import GetStore from "../GetStore";

import {
  ContainerBackground,
  DatePicker,
  ContainerInfo,
  TopSide,
  HeaderInfo,
  IconContent,
  Checkbox,
  Row,
  Button,
  IconShoppingCart
} from "./styles";

interface IProps {
  title: string;
  textTitle?: string;
  Img: string;
  store: number | undefined | null;
  setStore?: Dispatch<SetStateAction<number | undefined | null>>;
  setStoreInfo?: Dispatch<SetStateAction<any | undefined | null>>;
  setShouldSearch: Dispatch<SetStateAction<boolean>>;
  checkBoxVisible?: boolean;
  checked?: boolean;
  setAll?: Dispatch<SetStateAction<boolean>>;
  permissionCheck?: string;
  textCheckBox?: string;
  buttonVisible?: boolean;
  permissionButton?: string;
  buttonClick?: () => void;
  buttonText?: string;
  setMinOrder?: Dispatch<SetStateAction<number | undefined>>;
  filterCountryPY?: boolean;
  filterCountryBR?: boolean;
  setBrasilCompanyIds?: Dispatch<SetStateAction<number[]>>;
  setParaguayCompanyIds?: Dispatch<SetStateAction<number[]>>;
  children?: React.ReactElement;
  date?: boolean;
  setSelectedDate?: Dispatch<SetStateAction<{ initial_date: Moment | null; final_date: Moment | null }>>;
  selectedDate?: { initial_date: Moment | null; final_date: Moment | null };
}

const StoreSelectionPage: React.FC<IProps> = ({
  title,
  textTitle,
  Img,
  store,
  setStore,
  setStoreInfo,
  setShouldSearch,
  checkBoxVisible,
  checked,
  setAll,
  permissionCheck,
  textCheckBox,
  buttonVisible,
  permissionButton,
  buttonClick,
  buttonText,
  setMinOrder,
  filterCountryPY,
  filterCountryBR,
  setBrasilCompanyIds,
  setParaguayCompanyIds,
  date,
  setSelectedDate,
  selectedDate,
}) => {
  const [selectedStore, setSelectedStore] = useState<number | null | undefined>(null);

  const handleDateChange = (date: Moment | null) => {
    if (date && setSelectedDate) {
      const initialDate = date.startOf("month");
      const finalDate = date.endOf("month");
      setSelectedDate({
        initial_date: initialDate,
        final_date: finalDate,
      });
      if (selectedStore) {
        setStore && setStore(selectedStore);
        setShouldSearch(true);
      }
    }
  };

  return (
    <ContainerBackground>
      <TopSide>
        <ContainerInfo>
          <HeaderInfo>
            <Row>
              <h3>{title}</h3>
            </Row>
            <Row>
              <p>{textTitle}</p>
            </Row>
            <GetStore
              defaultValue={store}
              handleChange={(id) => {
                if (date) {
                  if (selectedDate?.initial_date) {
                    setStore && setStore(id);
                    setShouldSearch(true);
                  } else {
                    setSelectedStore(id)
                  }
                } else {
                  setStore && setStore(id);
                  setShouldSearch(true);
                }
              }}
              handleChangeStore={(value) => {
                setStoreInfo && setStoreInfo(value);
              }}
              setMinOrder={setMinOrder}
              filterCountryPY={filterCountryPY}
              filterCountryBR={filterCountryBR}
              setParaguayCompanyIds={setParaguayCompanyIds}
              setBrasilCompanyIds={setBrasilCompanyIds}
            />
            {date && (
              <DatePicker
                picker="month"
                placeholder="Selecione o mês de competência"
                onChange={handleDateChange}
                locale={locale}
                style={{ marginTop: "20px" }}
              />
            )}
            <Row style={{ justifyContent: "flex-end" }}>
              {checkBoxVisible && (
                <>
                  {verifyPermission(permissionCheck) && (
                    <Checkbox
                      checked={checked}
                      onChange={() => {
                        setShouldSearch(true);
                        if (setAll) {
                          setAll(!checked);
                        }
                      }}
                    >
                      {textCheckBox}
                    </Checkbox>
                  )}
                </>
              )}
            </Row>
            <Row>
              {buttonVisible && (
                <>
                  {verifyPermission(permissionButton) && (
                    <Button onClick={buttonClick}>
                      <IconShoppingCart />
                      <span>{buttonText}</span>
                    </Button>
                  )}
                </>
              )}
            </Row>
          </HeaderInfo>
        </ContainerInfo>
        <IconContent>
          <img src={Img} alt="" />
        </IconContent>
      </TopSide>
    </ContainerBackground>
  );
};

export default StoreSelectionPage;
