import styled, { css } from "styled-components";
import {
  Button as ButtonAnt,
  Select as SelectAnt,
  Input as InputAnt,
  DatePicker as DatePickerAnt,
} from "antd";
import { ChevronLeft, InfoCircle } from "../../../styles/Icons";

export const Select = styled(SelectAnt)`
  width: 100%;
  height: 2.5rem !important;

  .ant-select-selection-item {
    display: flex;
    align-items: center;
  }

  .ant-select-selector {
    height: 2.5rem !important;
  }
`;

export const Validate = styled.span`
  color: red;
`;

export const InputFile = styled.input`
  display: none !important;
`;

export const StyledInput = styled.label`
  display: inline-block;
  padding: 10px 20px;
  background-color: transparent;
  color: var(--orange-350);
  border: 1px solid var(--orange-350);
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
`;

export const ContentCheckbox = styled.div`
  display: flex;
  width: 100%;
  align-items: center;

  margin-bottom: 1rem;
`;

export const ContentFileView = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`;

export const CheckboxLabel = styled.span`
  font-weight: 400;
  font-size: 0.9rem;
  color: var(--gray-45);
  margin-left: 0.5rem;
`;

export const ChevronIcon = styled(ChevronLeft)`
  color: black;
  cursor: pointer;
  width: 1.2rem;
  height: 2.2rem;
`;

export const Input = styled(InputAnt)`
  width: 100%;
  height: 2.5rem !important;
  border-radius: 5px !important;

  .ant-input {
    height: 1.9rem !important;
  }
`;

export const DatePicker = styled(DatePickerAnt)`
  width: 100%;
  height: 2.5rem !important;

  .ant-picker-input > input {
    font-weight: 400 !important;
    font-size: 0.9rem !important;
  }
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;

  @media only screen and (max-width: 578px) {
    width: 100%;
    overflow-y: scroll;
  }
`;

export const AddNewSupplier = styled.button`
  background-color: transparent;
  border: none;

  color: var(--gray-45);
  font-size: 0.9rem;

  margin-bottom: 1rem;
`;

export const Title = styled.span`
  color: var(--gray-45);
  font-size: 1.2rem;
  text-transform: capitalize;
  margin-top: 3rem;
`;

const ButtonCSS = css`
  font-weight: 500;
  font-size: 1rem;
  width: 7rem;
  height: 3rem;
  border-radius: 5px;
`;

export const ButtonCancel = styled.button`
  ${ButtonCSS}
  color: var(--gray-650);

  :hover {
    background-color: var(--white-100);
  }
`;

export const ButtonSave = styled(ButtonAnt)`
  ${ButtonCSS}
  color: white;
  background-color: var(--orange-350);
  transition: 0.2s;

  :hover,
  :active,
  :focus {
    background-color: var(--orange-600);
    border: 1px solid var(--orange-600);
    color: white;
  }
`;

export const Footer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: start;
`;

export const InfoCircleIcon = styled(InfoCircle)`
  width: 15px;
  height: 15px;
  margin-left: 5px;

  cursor: pointer;
  color: var(--c64-blue);
`;

export const UploadWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2%;

  input[type="file"]::file-selector-button {
    border: none;
    background: var(--orange-gradient);
    padding: 10px 20px;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    width: 100%;
  }
`;

export const Button = styled.div`
  ${ButtonCSS}
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--gray-650);

  :hover {
    background-color: var(--white-100);
  }
`;
