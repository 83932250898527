import React, { useState, useEffect } from "react";
import { getTokenInfo } from "../../services/auth";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { logout } from "../../services/auth";
import { verifyPermission } from "../../services/auth";

import Paraguai from "../../assets/images/paraguai.webp";
import LogoImg from "../../assets/images/logo.png";

import {
  HomeIcon,
  BenchMarkIcon,
  CheckIcon,
  LogOutIcon,
  LogoContainer,
  NavMenuContainer,
  NavItem,
  Footer,
  CloseIcon,
  MenuIcon,
  Container,
  MenuContainer,
  UserInfoContainer,
  ButtonLogout,
  PhoneIcon,
  NfeIcon,
  MapPinIcon,
  TruckIcon,
  ParaguaiImg,
} from "./styles";

interface IProps extends RouteComponentProps<any> {}

const SideMenu: React.FC<IProps> = ({ history }) => {
  const [currentRoute, setCurrentRoute] = useState<string | null>(null);

  const [isVisible, setIsVisible] = useState<boolean>(false);

  const formatPerfil = (perfil?: number) => {
    if (perfil === 1) {
      return "Master";
    }
    if (perfil === 2) {
      return "Admin";
    }
    return "Usuário";
  };

  const redirect = (route?: string) => {
    return history.push(`/${route}`);
  };

  const verifyCountry = (country: string): boolean => {
    const userCountries = getTokenInfo()?.countries ?? [];
    return userCountries.includes(country);
  };

  const menus = [
    {
      id: 1,
      label: "Administração",
      path: "/",
      icon: <HomeIcon />,
      permission: null,
    },
    {
      id: 2,
      label: "Benchmark",
      path: "/benchmark",
      icon: <BenchMarkIcon />,
      permission: "benchmarking.route",
    },
    {
      id: 3,
      label: "Pedidos",
      path: "/orders",
      icon: <CheckIcon />,
      permission: "orders.route",
      country: "brasil",
    },
    {
      id: 5,
      label: "Pedidos PY",
      path: "/orders_py",
      icon: <ParaguaiImg src={Paraguai} />,
      permission: "orders.route",
      country: "paraguai",
    },
    // {
    //   id: 4,
    //   label: "Vendas do Club",
    //   path: "/pontos_app",
    //   icon: <PhoneIcon />,
    //   permission: null,
    // },
    // {
    //   id: 6,
    //   label: "Rotas",
    //   path: "/routes",
    //   icon: <MapPinIcon />,
    //   permission: "routes.view",
    // },
    // {
    //   id: 7,
    //   label: "Motorista",
    //   path: "/driver",
    //   icon: <TruckIcon />,
    //   permission: "driver.view",
    // },
  ];

  useEffect(() => {
    setCurrentRoute(history.location.pathname);
  }, [history.location.pathname]);

  const isSamePath = (_path: string): boolean => {
    return _path === currentRoute;
  };

  return (
    <>
      {isVisible ? (
        <CloseIcon onClick={() => setIsVisible(false)} />
      ) : (
        <MenuIcon onClick={() => setIsVisible(true)} />
      )}
      <>
        <Container responsive={isVisible}>
          <span
            className="logout-mobile"
            onClick={() => {
              logout();
              redirect();
            }}
          >
            Sair
          </span>
          <LogoContainer>
            <img src={LogoImg} alt="Logo Amadelli The Best Açaí" />
          </LogoContainer>

          <NavMenuContainer>
            {menus.map((_menu) => {
              const hasPermission =
                !_menu.permission || verifyPermission(_menu.permission);
              const countryCheck =
                !_menu.country || verifyCountry(_menu.country);

              if (hasPermission && countryCheck) {
                return (
                  <React.Fragment key={_menu.id}>
                    <MenuContainer
                      key={_menu.id}
                      onClick={() => setIsVisible(false)}
                      actived={!isVisible}
                    >
                      <NavItem
                        to={_menu.path}
                        actived={isSamePath(_menu.path).toString()}
                      >
                        {_menu.icon}
                        <span>{_menu.label}</span>
                      </NavItem>
                    </MenuContainer>
                  </React.Fragment>
                );
              }
              return null;
            })}
          </NavMenuContainer>
          <Footer>
            <span className="perfil">PERFIL</span>
            <UserInfoContainer>
              <img
                src={
                  getTokenInfo()?.image
                    ? getTokenInfo()?.image
                    : "https://www.ecp.org.br/wp-content/uploads/2017/12/default-avatar-1.png"
                }
                alt=""
              />

              <div className="info-user">
                <span className="name-user">{getTokenInfo()?.name}</span>
                <span className="role-user">
                  {formatPerfil(getTokenInfo()?.level)}
                </span>
              </div>
            </UserInfoContainer>

            <ButtonLogout
              onClick={() => {
                logout();
                redirect();
              }}
            >
              <LogOutIcon />
              <span>Sair</span>
            </ButtonLogout>
          </Footer>
        </Container>
      </>
    </>
  );
};

export default withRouter(SideMenu);
