import React, { useEffect, useState } from 'react';
import { LabelItem, ModalPartialPayment, PartialTotal, RowElement, RowPartialPaymentModal, Select } from './styles';
import { ButtonCancel, ButtonSave, ContentModal } from '../styles';
import OrderPaymentType from "../../../models/json/OrderPaymentType.json";
import MonetaryInput2 from '../../../components/MonetaryInput2';
import { AccountPayable } from '../../../models/AccountsPayable';
import { currencyFormater } from '../../../services/currencyFormater';
import { Col, Form, notification, Row } from 'antd';
import apiMercury from '../../../services/apiMercury';

interface IProps {
    selectedBill: AccountPayable | undefined;
    open: boolean;
    loading: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setShouldSearch: React.Dispatch<React.SetStateAction<boolean>>;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const PartialPaymentModal: React.FC<IProps> = ({
    open,
    selectedBill,
    setOpen,
    setLoading,
    setShouldSearch,
    loading
}) => {
    const [form] = Form.useForm();

    const [payment, setPayment] = useState<number>(0);
    const [paymentType, setPaymentType] = useState<number | null>(5);
    const [valuePayment, setValuePayment] = useState<number>(0);

    const calculateTotalPayment = (accountPayable: AccountPayable) => {
        return accountPayable.payment_amount
            ? parseFloat(accountPayable.payment_amount.toString())
            : 0;
    };

    const totalPayment = selectedBill ? calculateTotalPayment(selectedBill) : 0;

    useEffect(() => {
        if (selectedBill) {
            const totalAmount = selectedBill.payment_amount ? parseFloat(selectedBill.payment_amount.toString()) : 0;
            const totalPaid = calculateTotalPayment(selectedBill);
            setValuePayment(totalAmount - totalPaid);
        }
    }, [selectedBill]);

    const resetFields = async () => {
        form.resetFields();
        setPayment(0);
        setPaymentType(1);
        setOpen(false);
    };

    const onFinish = async () => {
        form.validateFields();

        if (payment === 0) {
            return notification.warning({
                message: "Preencha os campos corretamente",
                description: "O campo Valor unitário deve ser preenchido",
                duration: 5,
            });
        }

        if (paymentType === null) {
            return notification.warning({
                message: "Preencha os campos corretamente",
                description: "Modo de pagamento deve ser selecionado",
                duration: 5,
            });
        }

        const remainingAmount = totalPayment - valuePayment;

        if (payment > remainingAmount) {
            return notification.warning({
                message: "Valor inválido",
                description: "O valor inserido ultrapassa o valor restante.",
                duration: 5,
            });
        }

        const payload = {
            accounts_payable_id: selectedBill?.id,
            payment_value: payment,
            type: paymentType
        };

        try {
            setLoading(true);
            await apiMercury.post("/accounts-payable", payload);

            notification.success({
                message: "Pagamento cadastrado",
                description: `O pagamento no valor de R$ ${currencyFormater(
                    +payment
                )} foi cadastrado com sucesso. Ainda há ${remainingAmount} em aberto. Regularize assim que possível`,
                duration: 10,
            });
            setShouldSearch(true);
            resetFields();
        } catch (error) {
            //@ts-ignore
            const _description = error.message;
            notification.error({
                message: "Oops! Não foi possível realizar o pagamento",
                description: error ? _description : "Falha ao realizar o pagamento.",
                duration: 5,
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <ModalPartialPayment
            title={"Pagamento Parcial"}
            centered
            visible={open}
            destroyOnClose={true}
            closable
            width={450}
            onCancel={() => setOpen(false)}
            footer={[
                <ButtonCancel onClick={() => resetFields()}>Cancelar</ButtonCancel>,
                <ButtonSave
                    loading={loading}
                    disabled={payment > (totalPayment - valuePayment)}
                    onClick={() => onFinish()}
                >
                    Pagar
                </ButtonSave>
            ]}
        >
            <Form layout="vertical" form={form}>
                <RowPartialPaymentModal>
                    <LabelItem>Total da Conta:</LabelItem>
                    <PartialTotal>{` R$ ${totalPayment ? currencyFormater(totalPayment) : 0}`}</PartialTotal>
                </RowPartialPaymentModal>

                <RowPartialPaymentModal>
                    <LabelItem>Total Pago:</LabelItem>
                    <PartialTotal>{` R$ ${totalPayment ? currencyFormater(valuePayment) : 0}`}</PartialTotal>
                </RowPartialPaymentModal>

                <RowPartialPaymentModal>
                    <LabelItem>Restante:</LabelItem>
                    <PartialTotal>{` R$ ${valuePayment > 0 ? currencyFormater(valuePayment) : 0}`}</PartialTotal>
                </RowPartialPaymentModal>

                <RowPartialPaymentModal>
                    <RowElement>
                        <Col xs={24}>
                            <Form.Item
                                name="type"
                                label="Método de Pagamento"
                                rules={[{ required: true, message: 'Por favor, selecione um método de pagamento!' }]}
                            >
                                <Select
                                    placeholder="Selecione um modo"
                                    onChange={(value) => setPaymentType(+value)}
                                >
                                    {OrderPaymentType.map((orderPaymentType) => (
                                        <Select.Option
                                            label={orderPaymentType.label}
                                            value={orderPaymentType.value}
                                            key={orderPaymentType.value}
                                        >
                                            {orderPaymentType.label}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </RowElement>
                </RowPartialPaymentModal>

                <RowPartialPaymentModal>
                    <RowElement>
                        <Col xs={24}>
                            <Form.Item
                                label="Valor a ser Pago"
                                rules={[{ required: true, message: 'Por favor, insira o valor a ser pago!' }]}
                            >
                                <MonetaryInput2
                                    style={{ height: "2.5rem" }}
                                    getValue={(value) => {
                                        setPayment(value);
                                        form.setFieldsValue({ payment: value });
                                    }}
                                    defaultValue={+payment}
                                />
                            </Form.Item>
                        </Col>
                    </RowElement>
                </RowPartialPaymentModal>
            </Form>
        </ModalPartialPayment>
    );
};

export default PartialPaymentModal;