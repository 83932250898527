import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import {
  ArrowIosForward,
  TrashIconA,
  ArrowRepeatAll,
  Dashboard,
  DocumentOnePage,
  AccountBalanceWallet,
  Ticket,
  People,
  BarcodeScanner,
} from "../../styles/Icons";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 1;
  height: 100%;
`;

export const NavCard = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(3, 1fr);
  padding: 1.2rem 0;
  grid-gap: 2rem 1.8rem;
  overflow: scroll;

  @media only screen and (max-width: 1560px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and (max-width: 1024px) {
    align-items: center;
    justify-content: center;
  }
  @media only screen and (max-width: 850px) {
    grid-template-columns: repeat(1, 1fr);
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const Card = styled(Link)`
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 8rem;
  width: 100%;
  border: 1px solid #e0e1e3;
  border-radius: 9px;

  cursor: pointer;

  @media only screen and (max-width: 578px) {
    width: 100%;
    height: 6.6rem;
    overflow: hidden;
  }

  :hover {
    border-radius: 9px;
    border: 1px solid var(--orange-350);
  }
`;

export const PageInfoContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  span {
    color: var(--gray-570);
    @media only screen and (max-width: 1024px) {
      font-size: 0.9rem;
    }
  }
`;

export const IconContent = styled.div`
  display: flex;
  width: 20%;
`;

export const InfoContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 70%;
`;

export const ArrowContent = styled.div`
  display: flex;
  text-align: center;
  width: 10%;
`;

export const Title = styled.label`
  width: 100%;
  font-size: 22px;
  font-weight: bold;
  line-height: 30px;
  color: var(--blue-900);

  @media only screen and (max-width: 578px) {
    font-size: 16px;
  }
`;

const ImgCSS = css`
  width: 4.5rem;
  height: 4.5rem;
  color: var(--orange-350);
  padding: 0.6rem;
  margin: auto;
`;

export const Icons = styled.img`
  ${ImgCSS}
`;

export const ArrowIcon = styled(ArrowIosForward)`
  ${ImgCSS}
`;

export const PeopleIcon = styled(People)`
  ${ImgCSS}
`;

export const TrashIcon = styled(TrashIconA)`
  ${ImgCSS}
`;

export const ArrowRepeatAllIcon = styled(ArrowRepeatAll)`
  ${ImgCSS}
`;

export const DashboardIcon = styled(Dashboard)`
  ${ImgCSS}
`;

export const BarcodeScannerIcon = styled(BarcodeScanner)`
  ${ImgCSS}
`;

export const NfeIcon = styled(DocumentOnePage)`
  ${ImgCSS}
`;

export const AccountBalanceWalletIcon = styled(AccountBalanceWallet)`
  ${ImgCSS}
`;

export const CuponIcon = styled(Ticket)`
  ${ImgCSS}
`;
