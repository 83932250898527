import React, { SetStateAction, Dispatch, useState } from "react";

import { withRouter, RouteComponentProps, Link } from "react-router-dom";

import { verifyPermission } from "../../services/auth";
import apiMercury from "../../services/apiMercury";
import { currencyFormater } from "../../services/currencyFormater";

import { Page } from "../../models/Page";
import { Purchase } from "../../models/Purchase/Purchase";
import { PaymentType } from "../../models/enums/PaymentType";
import { ProductCategory } from "../../models/ProductCategory";
import { PaymentAccountType } from "../../models/enums/PaymentAccountStatus";

import ModalImportStock from "../../components/ModalImportStock";

import { Modal, Dropdown, Menu, Tooltip } from "antd";

import moment from "moment";
import swal from "sweetalert";

import {
  Container,
  Action,
  RemoveIcon,
  ItemsSelectedContainer,
  ItemsSelectedInfo,
  QtdItems,
  InfoQtdItems,
  ButtonsContainer,
  LiquidarButton,
  RemoverButton,
  InfoCircleIcon,
  CheckIcon,
  MoreIcon,
  Table,
  ContentTile,
  MoneyIcon,
  TransferIcon,
  BarcodeIcon,
  PixIcon,
  SelectMultipleIcon,
  CreditCardIcon,
  DebitCardIcon,
} from "./styles";

interface IProps extends RouteComponentProps {
  setShouldSearch: Dispatch<SetStateAction<boolean>>;
  shouldSearch: boolean;
  setPurchaseId?: Dispatch<SetStateAction<number | undefined>>;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  purchases: Purchase[];
  buttonInfo: (id: number) => void;
  setVisibleOrderBuy: Dispatch<SetStateAction<boolean>>;
  isStoreActive: boolean | undefined;
  productsCategory: ProductCategory[];
  store: number | null | undefined;
  page: number | undefined;
  size: number | undefined;
  totalElements: number;
  setPaginate: React.Dispatch<React.SetStateAction<Page>>
}

const { confirm } = Modal;

const ShoppingList: React.FC<IProps> = ({
  loading,
  purchases,
  isStoreActive,
  productsCategory,
  page,
  size,
  totalElements,
  setPurchaseId,
  setShouldSearch,
  setLoading,
  buttonInfo,
  setVisibleOrderBuy,
  setPaginate,
  store
}) => {
  const [purchasesAdd, setPurchasesAdd] = useState<number[]>([]);
  const [visibleStockImport, setVisibleStockImport] = useState(false);
  const [stockPurchaseID, setStockPurchaseID] = useState<number>();

  const handleRemovePurchase = async (purchase: Purchase, action: string) => {
    confirm({
      title:
        action === "delete" ? "Remover Compra" : "Estornar Royalty/Marketing",
      content: `Gostaria de ${action === "delete" ? "excluir" : "estornar"
        } esta compra?`,
      okText: "Sim",
      okType: "danger",
      cancelText: "Não",

      async onOk() {
        try {
          await apiMercury.delete(`/purchases/${purchase?.id}`);
          swal(
            `Compra ${action === "delete" ? "excluida" : "estornada"
            } com sucesso!`,
            "",
            "success"
          );
          setShouldSearch(true);
        } catch {
          swal(
            `Erro ao ${action === "delete" ? "excluir" : "estornar"} compra`,
            "",
            "error"
          );
        }
      },
    });
  };

  const handlePaymentMany = () => {
    confirm({
      title: "Confirmar Pagamento",
      content:
        "Deseja continuar e confirmar o pagamento da(s) compra(s) selecionada(s)?",
      okText: "Sim",
      okType: "primary",
      cancelText: "Não",
      async onOk() {
        setLoading(true);

        try {
          await apiMercury.patch(`/purchases`, {
            ids: purchasesAdd,
            pay_date: moment(new Date()).toISOString(),
          });

          setPurchasesAdd([]);
          setShouldSearch(true);
          swal("Pagamento confirmado com sucesso!", "", "success");
        } catch {
          swal("Erro ao confirmar pagamento!", "", "error");
        } finally {
          setLoading(false);
        }
      },
    });
  };

  const handleRemoveMany = () => {
    confirm({
      title: "Excluir compras",
      content: "Deseja continuar e excluir a(s) compra(s) selecionada(s)?",
      okText: "Sim",
      okType: "primary",
      cancelText: "Não",
      async onOk() {
        setLoading(true);
        try {
          await Promise.all(
            purchasesAdd.map(async (id) => {
              await apiMercury.delete(`/purchases/${id}`);
            })
          );
          setPurchasesAdd([]);
          swal("Compra(s) removida(s) com sucesso!", "", "success");
          setShouldSearch(true);
        } catch {
          swal("Falha ao remover compra(s)!", "", "error");
        } finally {
          setLoading(false);
        }
      },
    });
  };

  const columns = [
    {
      title: (
        <ContentTile>
          ID <Tooltip title="Identificador numérico sequencial"><InfoCircleIcon /></Tooltip>
        </ContentTile>
      ),
      dataIndex: "id",
      key: "id",
      responsive: ["md"] as any,
      width: "15%",
      render: (_, record) => (
        <Tooltip title={record.id}>
          <span>{record.id}</span>
        </Tooltip>
      ),
      sorter: (a, b) => a.id - b.id,
      defaultSortOrder: "descend" as any,
    },
    {
      title: (
        <ContentTile>
          Mês de Competência <Tooltip title="Mês de Competência do lançamento"><InfoCircleIcon /></Tooltip>
        </ContentTile>
      ),
      dataIndex: "purchase_date",
      key: "purchase_date",
      width: "20%",
      render: (text, record) => (
        <span>{record?.purchase_date ? moment(record?.purchase_date).format("MM/YYYY") : "-"}</span>
      ),
      sorter: (a, b) => moment(a.purchase_date).unix() - moment(b.purchase_date).unix(),
    },
    {
      title: (
        <ContentTile>
          Data de Vencimento <Tooltip title="Data de Vencimento"><InfoCircleIcon /></Tooltip>
        </ContentTile>
      ),
      dataIndex: "due_date",
      key: "due_date",
      width: "25%",
      render: (text, record) => (
        <span>{record?.due_date ? moment(record?.due_date).format("DD/MM/YYYY") : " - "}</span>
      ),
      sorter: (a, b) => moment(a.due_date).unix() - moment(b.due_date).unix(),
    },
    {
      title: (
        <ContentTile>
          Data de Criação <Tooltip title="Data de Criação da Compra"><InfoCircleIcon /></Tooltip>
        </ContentTile>
      ),
      dataIndex: "created_at",
      key: "created_at",
      width: "20%",
      render: (text, record) => (
        <span>{moment(record?.created_at).format("DD/MM/YYYY HH:mm:ss")}</span>
      ),
      sorter: (a, b) => moment(a.created_at).unix() - moment(b.created_at).unix(),
    },
    {
      title: (
        <ContentTile>
          Fornecedor <Tooltip title="Empresa responsável pela venda dos produtos"><InfoCircleIcon /></Tooltip>
        </ContentTile>
      ),
      dataIndex: "provider",
      key: "provider",
      ellipsis: true,
      width: "20%",
      render: (text, record) => (
        <Tooltip title={record?.provider?.fantasy_name}>
          <span>{record?.provider?.fantasy_name ? record?.provider?.fantasy_name : "Sem Fornecedor"}</span>
        </Tooltip>
      ),
      sorter: (a, b) =>
        (a.provider?.fantasy_name || "").localeCompare(b.provider?.fantasy_name || ""),
    },
    {
      title: (
        <ContentTile>
          Observação <Tooltip title="Comentário sobre as compras, como código do boleto ou referência"><InfoCircleIcon /></Tooltip>
        </ContentTile>
      ),
      dataIndex: "observation",
      key: "observation",
      ellipsis: true,
      responsive: ["md"] as any,
      width: "25%",
      render: (text, record) => (
        <Tooltip title={record?.observation ? record?.observation : "Sem observações"}>
          <span>{record?.observation ? record?.observation : "Sem observações"}</span>
        </Tooltip>
      ),
      sorter: (a, b) =>
        (a.observation || "").localeCompare(b.observation || ""),
    },
    {
      title: (
        <ContentTile>
          Valor <Tooltip title="Valor em reais da compra. O ícone ao lado indica o método de pagamento"><InfoCircleIcon /></Tooltip>
        </ContentTile>
      ),
      dataIndex: "total",
      key: "total",
      width: "25%",
      render: (text, record) => {
        const paymentMethod = record?.accountsPayable?.[0]?.payment_method;

        const renderIcon = () => {
          switch (paymentMethod) {
            case PaymentType.Dinheiro:
              return <MoneyIcon />;
            case PaymentType.Transferência:
              return <TransferIcon />;
            case PaymentType.Boleto:
              return <BarcodeIcon />;
            case PaymentType.C_Crédito:
              return <CreditCardIcon />;
            case PaymentType.C_Débito:
              return <DebitCardIcon />;
            case PaymentType.Pix:
              return <PixIcon />;
            default:
              return (
                <SelectMultipleIcon />
              );
          }
        };

        return (
          <Tooltip title={currencyFormater(+record?.total)}>
            <ContentTile>
              R$ {currencyFormater(+record?.total)} {renderIcon()}
            </ContentTile>
          </Tooltip>
        );
      },
      sorter: (a, b) => a.total - b.total,
    },
    {
      title: "Ações",
      dataIndex: "",
      key: "",
      textWrap: "word-break",
      width: window.outerWidth > 768 ? "15%" : "8%",
      render: (text, record) => (
        <Action>
          {!isStoreActive && (
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item
                    onClick={() => {
                      setVisibleStockImport(true);
                      setStockPurchaseID(record?.id);
                    }}
                  >
                    {record?.already_imported_as_stock
                      ? "Estornar do Estoque"
                      : "Importar para o Estoque"}
                  </Menu.Item>
                  <Menu.Item onClick={() => buttonInfo(record)}>
                    Informações da Compra
                  </Menu.Item>
                  {(record?.name === "royalties" ||
                    record?.name === "marketing" ||
                    record?.name === "sistema" ||
                    record?.name === "serviço") && (
                      <Menu.Item onClick={() => handleRemovePurchase(record, "reverse")}>
                        <span>Estornar Royalty/Marketing</span>
                      </Menu.Item>
                    )}
                  {verifyPermission("shop.edit") &&
                    !(
                      record?.name === "royalties" ||
                      record?.name === "marketing" ||
                      record?.name === "sistema" ||
                      record?.name === "serviço"
                    ) && (
                      <Menu.Item>
                        <Link
                          to={{
                            pathname: '/new-shop',
                            state: { record, productsCategory, store }
                          }}
                        >
                          Visualizar Itens da Compra
                        </Link>
                      </Menu.Item>
                    )}
                  <Menu.Item onClick={() => handleRemovePurchase(record, "delete")}>
                    <span style={{ color: "var(--orange-600)" }}>
                      Excluir Compra
                    </span>
                  </Menu.Item>
                </Menu>
              }
              trigger={["click"]}
              placement="bottomCenter"
              arrow
            >
              <MoreIcon />
            </Dropdown>
          )}
        </Action>
      ),
    },
  ];

  return (
    <>
      <Container>
        <Table
          loading={loading}
          columns={columns}
          dataSource={purchases.map((entity) => ({
            ...entity,
            key: entity.id,
          }))}
          pagination={{
            current: page,
            pageSize: size,
            total: totalElements,
            showSizeChanger: false,
            onChange: (newPage, newSize) => {
              setPaginate((prev) => ({
                ...prev,
                page: newPage,
                size: newSize
              }));
              setShouldSearch(true);
            }
          }}
        />
      </Container>
      {purchasesAdd.length > 0 && (
        <ItemsSelectedContainer>
          <ItemsSelectedInfo>
            <QtdItems>{purchasesAdd.length}</QtdItems>
            <InfoQtdItems>
              {purchasesAdd.length === 1
                ? "item selecionado"
                : "itens selecionandos"}
            </InfoQtdItems>
            <ButtonsContainer>
              <LiquidarButton onClick={() => handlePaymentMany()}>
                {" "}
                <CheckIcon />
                Liquidar
              </LiquidarButton>
              {verifyPermission("shop.delete") && (
                <RemoverButton onClick={() => handleRemoveMany()}>
                  <RemoveIcon />
                  Remover
                </RemoverButton>
              )}
            </ButtonsContainer>
          </ItemsSelectedInfo>
        </ItemsSelectedContainer>
      )}

      <ModalImportStock
        visible={visibleStockImport}
        setVisible={setVisibleStockImport}
        setShouldSearch={setShouldSearch}
        stockPurchaseID={stockPurchaseID}
      ></ModalImportStock>
    </>
  );
};

export default withRouter(ShoppingList);
