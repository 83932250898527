import styled, { css } from "styled-components";

import {
  Row as RowAnt,
  Button as ButtonAnt,
  Select as SelectAnt,
  Table as TableAnt,
  DatePicker,
} from "antd";
import { Payments } from "../../../styles/Icons";

const { RangePicker: RangePickerAnt } = DatePicker;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  width: 100%;

  .ant-select-selector {
    height: 2.5rem !important;
  }
`;

export const Header = styled(RowAnt)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 15%;
  min-height: 3rem;

  h2 {
    font-size: 1.2rem;
    color: var(--gray-45);
    font-weight: 500;
    margin-bottom: 0;
  }
  @media only screen and (max-width: 800px) {
    min-height: 6rem;
    h2 {
      display: none;
    }
  }
  @media only screen and (max-width: 575px) {
    min-height: 12rem;
  }
`;

export const Button = styled(ButtonAnt)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.5rem;
  background: var(--orange-gradient);
  color: white;
  border-radius: 0.31rem;
  border-color: var(--orange-gradient);

  :hover,
  :focus {
    filter: contrast(0.8);
    background: var(--orange-gradient);
    color: white;
    border-color: var(--orange-gradient);
  }
`;

export const BoxChangePayment = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;

export const Select = styled(SelectAnt)`
  width: 15rem;
`;

export const RangePicker = styled(RangePickerAnt)`
  width: 15rem;
`;

export const StatusDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
`;

interface IOrderStatus {
  order_status: number;
  to_delivery: boolean;
}

export const StatusInfo = styled.b<IOrderStatus>`
  font-size: 1rem;
  font-weight: 400;
  overflow: hidden;
  hyphens: auto;
  word-break: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  ${({ order_status, to_delivery }) => {
    if (to_delivery && order_status < 4) {
      return css`
        border: 1px solid var(--chain-gang-grey);
        border-radius: 0.4rem;

        color: var(--chain-gang-grey);
        padding: 0.1rem;
      `;
    } else {
      if (order_status === 0) {
        return css`
          color: var(--night-shift);
        `;
      } else if (order_status === 1) {
        return css`
          color: var(--clear-chill);
        `;
      } else if (order_status === 2) {
        return css`
          color: var(--goldenrod-tea);
        `;
      } else if (order_status === 3) {
        return css`
          color: var(--midori-green);
        `;
      } else if (order_status === 4) {
        return css`
          color: var(--maroon);
        `;
      } else if (order_status === 5) {
        return css`
          color: var(--chain-gang-grey);
        `;
      } else if (order_status === 6) {
        return css`
          color: var(--chanterelle);
        `;
      }
    }
  }};
  @media only screen and (max-width: 1400px) {
    font-size: 0.75rem;
  }
`;
export const Table = styled(TableAnt)`
  height: 100%;

  .ant-table-container {
    border-radius: 5px;
    border: 1px solid var(--gray-75);
    overflow-y: scroll;

    ::-webkit-scrollbar {
      width: 0.3rem;
    }

    ::-webkit-scrollbar-track {
      background: var(--white-50);
      border-radius: 5px;
    }

    ::-webkit-scrollbar-thumb {
      background: var(--gray-300);
      border-radius: 5px;
    }
  }

  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: none;
  }

  .ant-table-tbody > tr > td {
    padding: 1rem 0.1rem;
    color: var(--blue-999);
    font-weight: 400;
    font-size: 1rem;
    overflow: hidden;
    hyphens: auto;
    word-break: break-word;
    overflow-wrap: break-word;
    white-space: normal;

    @media only screen and (max-width: 1400px) {
      font-size: 0.8rem;
    }
    @media only screen and (max-width: 575px) {
      padding: 0.15rem;
      font-size: 0.7rem;
    }
  }

  .ant-table-thead > tr > th {
    padding: 1rem 0.1rem;
    background: #fdfdfd;
    font-size: 1rem;
    font-weight: 500;
    color: var(--blue-900);
    text-overflow: ellipsis;
    @media only screen and (max-width: 1400px) {
      font-size: 0.8rem;
    }
    @media only screen and (max-width: 575px) {
      padding: 0.15rem;
      font-size: 0.7rem;
    }
  }
  .totalPay {
    & > td:first-child {
      border-left: 5px solid #71ea3f;
    }
    &:hover {
      background: #bbe88ed9;
    }
  }
  .partialPay {
    & > td:first-child {
      border-left: 5px solid orange;
    }
    &:hover {
      background: #de751c78;
    }
  }
  .emptyPay {
    & > td:first-child {
      border-left: 5px solid red;
    }
    &:hover,
    :active {
      background: #e4737952 !important;
    }
  }
  .default {
    &:hover,
    :active {
      background: #f6f6f6 !important;
    }
  }
`;

export const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonTax = styled(ButtonAnt)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  border-radius: 0.31rem;
  background: var(--orange-gradient);
  border-color: var(--orange-gradient);

  :hover,
  :focus {
    filter: contrast(0.8);
    background: var(--orange-gradient);
    border-color: var(--orange-gradient);
  }
`;

export const PaymentIcon = styled(Payments)`
  width: 1.5rem;
  height: 1.5rem;
  zoom: 1.2;
  cursor: pointer;
  @media only screen and (max-width: 575px) {
    width: 0.8rem;
    height: 0.8rem;
  }
  color: white;
`;
