import React, { useEffect, useState } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";

import "moment/locale/pt-br";

import apiAuth from "../../services/apiAuth";
import { verifyPermission } from "../../services/auth";

import PageContainer from "../../containers/PageContainer";

import ModalPatchNotes from "../../components/ModalPatchNotes";

import StockIconAdm from "../../assets/svg/StockIconAdm.svg";
import PurchaseIconAdm from "../../assets/svg/PurchaseIconAdm.svg";
import ProductIconAdm from "../../assets/svg/ProductIconAdm.svg";
import RoyaltyIconAdm from "../../assets/svg/RoyaltyIconAdm.svg";
import HistoryIconAdm from "../../assets/svg/HistoryIconAdm.svg";
import GraphIconAdm from "../../assets/svg/GraphIconAdm.svg";
import LocationIconAdm from "../../assets/svg/LocationIconAdm.svg";
import ManagerIconAdm from "../../assets/svg/ManagerIconAdm.svg";
import CategoryIcon from "../../assets/svg/CategoryIcon.svg";

import { Spin, notification } from "antd";

import {
  Container,
  InfoContent,
  Card,
  NavCard,
  Title,
  PageInfoContent,
  IconContent,
  ArrowIcon,
  ArrowContent,
  Icons,
  TrashIcon,
  ArrowRepeatAllIcon,
  DashboardIcon,
  NfeIcon,
  AccountBalanceWalletIcon,
  CuponIcon,
  PeopleIcon,
  BarcodeScannerIcon
} from "./styles";

interface IProps extends RouteComponentProps<any> {
  viewed: boolean;
}

interface PatchNotes {
  url: string;
  id: string;
  created_at: string;
}

const Administration: React.FC<IProps> = ({ history, viewed }) => {
  const [visiblePatchNotes, setVisiblePatchNotes] = useState(true);
  const [loading, setLoading] = useState(true);
  const [patchNotes, setPatchNotes] = useState<PatchNotes>({
    url: "",
    id: "",
    created_at: "",
  });
  const [isViewed, setIsViewed] = useState(viewed);

  useEffect(() => {
    async function init() {
      try {
        const {
          data: { content },
        } = await apiAuth.get("/patch-notes/last");

        let lastPatchNotes = window.localStorage.getItem("patch-notes");
        if (lastPatchNotes) {
          lastPatchNotes = JSON.parse(lastPatchNotes);
        }
        //@ts-ignore
        if (lastPatchNotes?.viewed === true) {
          setVisiblePatchNotes(false);
        }
        setPatchNotes({
          url: content.url,
          id: content.id,
          created_at: content.created_at,
        });
        setIsViewed(true);
        // @ts-ignore
        if (lastPatchNotes?.id !== content.id) {
          setVisiblePatchNotes(true);
          window.localStorage.setItem(
            "patch-notes",
            JSON.stringify({ ...content, viewed: true })
          );
        }
        if (!content) {
          return notification.warning({
            message: "Patch Notes não encontrado",
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
    init();
  }, []);

  const menus = [
    {
      id: 1,
      label: "Boletos e Notas fiscais",
      description: "Acompanhe e controle os boletos e notas fiscais de sua loja.",
      path: "/bills-and-invoices",
      icon: <BarcodeScannerIcon />,
      permission: "bills-and-invoices.route",
    },
    {
      id: 2,
      label: "Estoque",
      description: "Acompanhe todos os dados detalhados sobre seu estoque.",
      path: "/stock",
      icon: <Icons src={StockIconAdm} />,
      permission: "stock.route",
    },
    {
      id: 3,
      label: "Balanço",
      description:
        "Acompanhe todos os dados detalhados sobre sua loja, tudo em tempo real.",
      path: "/analytics",
      icon: <Icons src={GraphIconAdm} />,
      permission: "analytics.route",
    },
    {
      id: 4,
      label: "Compras",
      description:
        "Acompanhe todos os dados detalhados sobre suas compras realizadas.",
      path: "/shop",
      icon: <Icons src={PurchaseIconAdm} />,
      permission: "shop.route",
    },
    {
      id: 5,
      label: "Produtos",
      description: "Acompanhe todos dados detalhados sobre seus produtos.",
      path: "/product",
      icon: <Icons src={ProductIconAdm} />,
      permission: "product.route",
    },
    {
      id: 6,
      label: "Gerenciamento de Caixa",
      description:
        "Acompanhe todos dados detalhados sobre seu histórico de caixa.",
      path: "/cash_management",
      icon: <Icons src={HistoryIconAdm} />,
      permission: "cash_history.route",
    },
    {
      id: 7,
      label: "Produtos do Gestor",
      description:
        "Acompanhe todos os dados detalhados sobre todos os produtos do gestor.",
      path: "/product_franchisee",
      icon: <Icons src={ManagerIconAdm} />,
      permission: "product_franchisee.route",
    },
    {
      id: 8,
      label: "Royalty e Marketing",
      description:
        "Acompanhe todos dados detalhados sobre os gastos com royalty e marketing.",
      path: "/royalty",
      icon: <Icons src={RoyaltyIconAdm} />,
      permission: "royalty.route",
    },
    {
      id: 9,
      label: "Critérios de Frete",
      description:
        "Acompanhe todos os dados detalhados sobre os critérios de frete.",
      path: "/standard_freight",
      icon: <Icons src={LocationIconAdm} />,
      permission: "standard_freight.route",
    },
    {
      id: 10,
      label: "Categoria",
      description: "Selecione e gerencie as categorias.",
      path: "/category",
      icon: <Icons src={CategoryIcon} />,
      permission: "category.route",
    },
    {
      id: 11,
      label: "Desperdícios",
      description: "Acompanhe o desperdício de sua loja.",
      path: "/waste",
      icon: <TrashIcon />,
      permission: "waste.route",
    },
    {
      id: 12,
      label: "Movimentações",
      description: "Acompanhe as movimentações de caixa.",
      path: "/movements",
      icon: <ArrowRepeatAllIcon />,
      permission: "movements.route",
    },
    {
      id: 13,
      label: "NFe de Devoluções",
      description: "Acompanhe e emita suas NFes.",
      path: "/nfe",
      icon: <NfeIcon />,
      permission: "nfe.route",
    },
    {
      id: 14,
      label: "Notas de Débito e NFS-e",
      description:
        "Acompanhe e emita notas de Débito e NFS-e para as franquias.",
      path: "/company_invoice",
      icon: <NfeIcon />,
      permission: "invoice.route",
    },
    {
      id: 15,
      label: "Contas a Pagar",
      description:
        "Acompanhe todas as contas pendentes de sua loja, incluindo pedidos, compras e mais.",
      path: "/bills-to-pay",
      icon: <AccountBalanceWalletIcon />,
      permission: "bills-to-pay.route",
    },
    {
      id: 16,
      label: "Cupons",
      description: "Dê baixas em cupons do app pelo dashboard.",
      path: "/club_cupom",
      icon: <CuponIcon />,
      permission: null,
    },
    {
      id: 17,
      label: "Folha de Pagamento",
      description: "Declare os custos com seus funcionários, como holerite, vales e FGTS.",
      path: "/people",
      icon: <PeopleIcon />,
      permission: "people.route",
    },
  ];

  return (
    <PageContainer route="Administração">
      <Container>
        <NavCard>
          {menus.map((_menu) => (
            <React.Fragment key={_menu.id}>
              {(!_menu.permission || verifyPermission(_menu.permission)) && (
                <Card to={_menu.path}>
                  {
                    <PageInfoContent>
                      <IconContent>{_menu.icon}</IconContent>
                      <InfoContent>
                        <Title>{_menu.label}</Title>
                        <span>{_menu.description}</span>
                      </InfoContent>
                      <ArrowContent>
                        <ArrowIcon />
                      </ArrowContent>
                    </PageInfoContent>
                  }
                </Card>
              )}
            </React.Fragment>
          ))}
        </NavCard>
        {loading ? (
          <>
            <Spin></Spin>
          </>
        ) : (
          <>
            <ModalPatchNotes
              id={patchNotes?.id}
              created_at={patchNotes?.created_at}
              viewed={isViewed}
              url={patchNotes?.url}
              visiblePatchNotes={visiblePatchNotes}
              setVisiblePatchNotes={setVisiblePatchNotes}
            ></ModalPatchNotes>
          </>
        )}
      </Container>
    </PageContainer>
  );
};

export default withRouter(Administration);
