import React, { Dispatch, SetStateAction } from 'react';
import { Tooltip } from 'antd';
import moment from 'moment';

import { PeopleAccounts } from '../../../models/People/PeopleAccounts';

import { currencyFormater } from '../../../services/currencyFormater';

import {
    ButtonTable,
    ContentFooter,
    ContentTile,
    ContentTopInfo,
    InfoCapitalized,
    InfoCircleIcon,
    ItemInfo,
    Label,
    Table,
    TextModal,
    Modal,
    DownloadIconBottom,
    ContentDownload,
    DescriptionLabel,
} from './styles';

interface IProps {
    visible: boolean;
    setVisible: Dispatch<SetStateAction<boolean>>;
    peopleInfo: PeopleAccounts[];
    selectedPeopleInfo: PeopleAccounts | undefined;
}

const ModalDetails: React.FC<IProps> = ({ visible, setVisible, peopleInfo, selectedPeopleInfo }) => {

    const columns = [
        {
            title: (
                <ContentTile>
                    Encargo <Tooltip title="Identificador numérico sequencial"><InfoCircleIcon /></Tooltip>
                </ContentTile>
            ),
            dataIndex: "charges_id",
            key: "charges_id",
            sorter: (a, b) => a.charges_id - b.charges_id,
            render: (_, record) => (
                <InfoCapitalized>
                    {record.product?.name || "Desconhecido"}
                </InfoCapitalized>
            ),
        },
        {
            title: (
                <ContentTile>
                    Data de Pagamento <Tooltip title="Identificador numérico sequencial"><InfoCircleIcon /></Tooltip>
                </ContentTile>
            ),
            dataIndex: "payment_date",
            key: "payment_date",
            render: (text) => <InfoCapitalized>{moment(text).format("DD/MM/YYYY")}</InfoCapitalized>,
        },
        {
            title: (
                <ContentTile>
                    Valor <Tooltip title="Identificador numérico sequencial"><InfoCircleIcon /></Tooltip>
                </ContentTile>
            ),
            dataIndex: "payment_amount",
            key: "payment_amount",
            render: (text) => <InfoCapitalized>R$ {currencyFormater(+text)}</InfoCapitalized>,
        },
    ]

    const totalAdditional = selectedPeopleInfo?.peopleCostItems.reduce(
        (sum, item) => sum + +(item.additional_value ?? 0),
        0
    );
    const totalDiscounts = selectedPeopleInfo?.peopleCostItems.reduce(
        (sum, item) => sum + +(item.discounts ?? 0),
        0
    );
    
    return (
        <Modal
            title="Detalhes do Item"
            destroyOnClose
            centered
            closable
            visible={visible}
            onCancel={() => setVisible(false)}
            width={window.outerWidth < 768 ? '90%' : '60%'}
            footer={[
                <ContentFooter>
                    <ButtonTable onClick={() => setVisible(false)}>
                        Fechar
                    </ButtonTable>
                </ContentFooter>
            ]}
        >
            <>
                <ContentTopInfo>
                    <ItemInfo>
                        <Label>Colaborador(a)</Label>
                        <TextModal>
                            {selectedPeopleInfo?.name}
                        </TextModal>
                    </ItemInfo>
                    <ItemInfo>
                        <Label>Mês de Competência</Label>
                        <TextModal>
                            {moment(selectedPeopleInfo?.month_competence).locale('pt-br').format("MMMM/YYYY")}
                        </TextModal>
                    </ItemInfo>
                    <ItemInfo>
                        <Label>Anexos</Label>
                        <ContentDownload>
                            {selectedPeopleInfo?.url_file ? (
                                <>
                                    <DownloadIconBottom
                                        onClick={() => window.open(selectedPeopleInfo.url_file, '_blank')}
                                    />
                                    <TextModal>
                                        {selectedPeopleInfo.url_file.split('/').pop() || 'Arquivo desconhecido'}
                                    </TextModal>
                                </>
                            ) : (
                                <TextModal>Nenhum anexo</TextModal>
                            )}
                        </ContentDownload>
                    </ItemInfo>
                </ContentTopInfo>

                <Table
                    columns={columns}
                    dataSource={peopleInfo.map((entity) => ({
                        ...entity,
                        key: entity.id,
                    }))}
                    pagination={false}
                />

                <ContentTopInfo>
                    <ItemInfo>
                        <Label>Observação</Label>
                        
                        <DescriptionLabel>
                            {selectedPeopleInfo?.observation ? selectedPeopleInfo?.observation : "Sem Observação"}
                        </DescriptionLabel>
                    </ItemInfo>
                    {selectedPeopleInfo?.peopleCostItems?.some(item => item.additional_value || item.discounts) && (

                        <ItemInfo>
                            <Label>Valor Adicional / Desconto</Label>
                            <TextModal>
                                R$ {currencyFormater(totalAdditional)} -
                                R$ {currencyFormater(totalDiscounts)}
                            </TextModal>
                        </ItemInfo>
                    )}
                    <ItemInfo>
                        <TextModal>
                            R$ {currencyFormater(+(selectedPeopleInfo?.total_value ?? 0))}
                        </TextModal>
                        <Label>Valor Total</Label>
                    </ItemInfo>
                </ContentTopInfo>
            </>
        </Modal>
    )
}

export default ModalDetails