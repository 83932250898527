import React, { useEffect, useState } from "react";

import { Link, RouteComponentProps, useHistory } from "react-router-dom";

import locale from "antd/es/date-picker/locale/pt_BR";

import apiAuth from "../../../services/apiAuth";
import apiMercury from "../../../services/apiMercury";
import apiS3Handler from "../../../services/apiS3Handler";
import { currencyFormater } from "../../../services/currencyFormater";

import PageContainer from "../../../containers/PageContainer";

import OrderPaymentType from "../../../models/json/OrderPaymentType.json";
import { PaymentCondition } from "../../../models/enums/PaymentCondition";

import MonetaryInput2 from "../../../components/MonetaryInput2";
import SearchProvider from "../../../components/SearchProvider";
import ModalProvider from "../../../components/ModalProvider";

import moment from "moment";

import { Checkbox, Col, Form, Modal, notification, Row, Tooltip } from "antd";

import {
  AddNewSupplier,
  ButtonCancel,
  ButtonSave,
  CheckboxLabel,
  ChevronIcon,
  Container,
  Content,
  ContentCheckbox,
  DatePicker,
  Footer,
  InfoCircleIcon,
  ContentFileView,
  Input,
  Select,
  Title,
  InputFile,
  StyledInput,
  Validate,
  Button,
} from "./styles";

interface ComponentProps extends RouteComponentProps<{}, {}, any> { }

type UploadedFile = {
  url_file: string;
  s3_key: string;
};

const NewAccountPayable: React.FC<ComponentProps> = ({ location }) => {
  const history = useHistory();

  const { state } = location;
  const { store } = state;

  const [categorySelect, setCategorySelect] = useState(0);

  const [form] = Form.useForm();
  const [loadingUpload, setLoadingUpload] = useState(false);

  const [selectedOcurrencyType, setSelectedOcurrencyType] = useState<
    number | null
  >(null);

  const [provider, setProvider] = useState<number | null>(null);

  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

  const [formValues, setFormValues] = useState({
    additionalValue: 0,
    discountValue: 0,
    valueInterest: 0,
    valueFreight: 0,
    totalAccount: 0,
    paymentValue: 0,
  });

  const [ocurrence, setOcurrence] = useState(1);

  useEffect(() => {
    const init = () => {
      const inicialCategory = state?.productsCategory?.find(
        (item) => item?.name?.toLowerCase() === "custo fixo"
      )?.id;

      setCategorySelect(inicialCategory);
    };
    init();
  }, []);

  const updateFormValue = (field: string, value: number | null) => {
    setFormValues((prev) => {
      const updatedValues = { ...prev, [field]: value || 0 };
      const totalAccount = calculateTotalAccount(updatedValues);
      return { ...updatedValues, totalAccount: +totalAccount.toFixed(2) };
    });
  };

  const calculateTotalAccount = ({
    paymentValue,
    additionalValue,
    discountValue,
    valueFreight,
    valueInterest,
  }: typeof formValues) => {
    return Math.max(
      0,
      paymentValue +
      additionalValue +
      valueFreight +
      valueInterest -
      discountValue
    );
  };

  useEffect(() => {
    setFormValues((prev) => ({
      ...prev,
      totalAccount: +calculateTotalAccount(prev).toFixed(2),
    }));
  }, [
    formValues.paymentValue,
    formValues.additionalValue,
    formValues.discountValue,
    formValues.valueFreight,
    formValues.valueInterest,
  ]);

  const uploadFiles = async (): Promise<UploadedFile[]> => {
    const inputFile = document.getElementById("input_file") as HTMLInputElement;

    if (!inputFile || !inputFile.files || inputFile.files.length === 0) {
      return [];
    }

    const inputFiles = Array.from(inputFile.files);
    const fileList = inputFiles.filter((file) =>
      selectedFiles.some((item) => item.name === file.name)
    );

    const results: UploadedFile[] = [];

    try {
      setLoadingUpload(true);

      for (const file of fileList) {
        const timeStamp = new Date().getTime();
        const fileNameWithTimeStamp = `${file.name}_${timeStamp}`;

        const formData = new FormData();
        const renamedFile = new File([file], fileNameWithTimeStamp, {
          type: file.type,
        });
        formData.append("file", renamedFile);
        formData.append("owner", "ti");
        formData.append("folder", "contas");
        formData.append("action", "create");

        try {
          const {
            data: { location, key },
          } = await apiS3Handler.post(
            `/s3-upload/upload/amatech-purchase-files`,
            formData
          );

          await apiAuth.post("/files-management", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });

          results.push({ url_file: location, s3_key: key });
        } catch (error) {
          const errorMessage =
            (error as any)?.response?.data?.message ||
            "Oops! Ocorreu um erro no upload do arquivo";

          notification.error({
            message: "Erro ao fazer upload do arquivo",
            description: `Arquivo: ${file.name} - ${errorMessage}`,
            duration: 5,
          });
        }
      }
    } catch (error) {
      notification.error({
        message: "Erro ao fazer upload",
        description: "Um erro inesperado ocorreu ao processar os arquivos.",
        duration: 5,
      });
    } finally {
      setLoadingUpload(false);
    }

    return results;
  };

  const handleSubmit = async () => {
    const values = await form.validateFields();
    Modal.confirm({
      title: "Cadastrar Conta?",
      content: "Deseja continuar e cadastrar essa conta a pagar?",
      okText: "Sim",
      okType: "primary",
      cancelText: "Não",
      centered: true,
      async onOk() {
        const accountsPayableFiles = await uploadFiles();

        const payload = {
          purchase_id: undefined,
          ocurrence: 1,
          account_type: 2,
          store_id: store,
          category_id: values.category_id,
          provider_id: provider,
          product_id: values?.product_id,
          payment_amount: formValues?.totalAccount || 0,
          due_date: moment(values?.due_date).toISOString(),
          payment_date: values?.payment_date
            ? moment(values?.payment_date).toISOString()
            : null,
          payment_method: values.payment_method,
          payment_condition: values.payment_condition || 1,
          issue_date: moment(values?.issue_date).toISOString(),
          month_competence: moment(values?.month_competence).toISOString(),
          observation: values.observation,
          accountsPayableFiles: accountsPayableFiles || [],
          payment_status: !!values?.payment_date,
          additional_value: formValues?.additionalValue || 0,
          discounts: formValues?.discountValue || 0,
          freight: formValues.valueFreight || 0,
          interest_amount: formValues?.valueInterest || 0,
          total_account: formValues?.totalAccount || 0,
          cod_bankslip: values?.cod_bankslip || "",
        };

        try {
          await apiMercury.post(`/accounts-payable`, payload);

          form.resetFields();
          notification.success({
            message: "Conta criada com sucesso",
            duration: 5,
          });

          history.push({
            pathname: "/bills-to-pay",
            state: { store_param: store },
          });

        } catch (error) {
          //@ts-ignore
          const _description = error?.response?.data?.error?.message;

          notification.error({
            message: `Oops, ocorreu um erro ao cadastrar a compra`,
            description: _description,
            duration: 5,
          });
        }
      },
    });
  };

  return (
    <PageContainer
      route="Nova Conta a Pagar"
      content={
        <>
          <Link
            to={{
              pathname: "/bills-to-pay",
              state: { store_param: store },
            }}
          >
            <ChevronIcon />
          </Link>
        </>
      }
    >
      <Container>
        <Form layout="vertical" initialValues={{ remember: false }} form={form}>
          <Content>
            <Row gutter={8}>
              <Col md={8} xs={24}>
                <Form.Item
                  label={
                    <span>
                      Fornecedor
                      <Tooltip title="Empresa responsável pela venda dos produtos">
                        <InfoCircleIcon />
                      </Tooltip>
                    </span>
                  }
                  name="provider_id"
                >
                  <SearchProvider
                    defaultValue={state.provider?.id}
                    handleChange={(value) => setProvider(value)}
                    height="2.5rem"
                  />
                </Form.Item>
              </Col>
              <Col md={8} xs={24}>
                <Form.Item
                  label={
                    <span>
                      Categoria
                      <Tooltip title="Categoria do produto">
                        <InfoCircleIcon />
                      </Tooltip>
                    </span>
                  }
                  name="category_id"
                  initialValue={
                    state?.productsCategory?.find(
                      (item) => item?.name?.toLowerCase() === "custo fixo"
                    )?.id
                  }
                  rules={[{ required: true, message: "Campo obrigatório" }]}
                >
                  <Select
                    onChange={(value) => {
                      form.setFieldsValue({
                        category_id: value,
                        product_id: "",
                      });
                      setCategorySelect(+value);
                    }}
                  >
                    {state?.productsCategory?.map((productCategory) => (
                      <Select.Option
                        value={productCategory.id}
                        key={productCategory.id}
                      >
                        {productCategory?.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col md={8} xs={24}>
                <Form.Item
                  label={
                    <span>
                      Serviço
                      <Tooltip title="Tipo de Serviço">
                        <InfoCircleIcon />
                      </Tooltip>
                    </span>
                  }
                  name="product_id"
                  rules={[{ required: true, message: "Campo obrigatório" }]}
                >
                  <Select
                    showSearch
                    placeholder="Selecione uma categoria"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toString()
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    onChange={(value) => {
                      form.setFieldsValue({ product_id: value });
                    }}
                  >
                    {state?.productsCategory
                      ?.find((item) => item?.id === categorySelect)
                      ?.products?.map((product) => (
                        <Select.Option
                          value={product.id}
                          key={product.id}
                          label={product.name}
                        >
                          {product.name}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={8}>
              <Col xs={24}>
                <Title>Pagamento</Title>
              </Col>
            </Row>

            <Row gutter={8}>
              <Col md={ocurrence !== PaymentCondition.A_VISTA ? 3 : 4} xs={24}>
                <Form.Item
                  label={
                    <span>
                      Valor
                      <Tooltip title="Preço de uma unidade do produto">
                        <InfoCircleIcon />
                      </Tooltip>
                      <Validate>*</Validate>
                    </span>
                  }
                  name={`payment_amount`}
                  rules={[
                    {
                      validator: () => {
                        return formValues.paymentValue
                          ? Promise.resolve()
                          : Promise.reject("Campo obrigatório");
                      },
                    },
                  ]}
                >
                  <MonetaryInput2
                    style={{ height: "2.5rem" }}
                    getValue={(value) =>
                      updateFormValue("paymentValue", +value)
                    }
                  />
                </Form.Item>
              </Col>
              <Col md={ocurrence !== PaymentCondition.A_VISTA ? 4 : 3} xs={24}>
                <Form.Item
                  label={
                    <span>
                      Ocorrência
                      <Tooltip title="Preço de uma unidade do produto">
                        <InfoCircleIcon />
                      </Tooltip>
                    </span>
                  }
                  name={`payment_condition`}
                >
                  <Select
                    placeholder=" À vista"
                    value={1}
                    disabled
                    onChange={(value) => {
                      form.setFieldsValue({ occurrence: 1 });
                      setOcurrence(+value);
                    }}
                  >
                    <Select.Option value={1} key={1}>
                      À vista
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col md={3} xs={24}>
                <Form.Item
                  label={
                    <span>
                      Valor Adicional
                      <Tooltip title="Valor Adicional da Compra">
                        <InfoCircleIcon />
                      </Tooltip>
                    </span>
                  }
                  name="additional_value"
                >
                  <MonetaryInput2
                    style={{ height: "2.5rem" }}
                    getValue={(value) =>
                      updateFormValue("additionalValue", +value)
                    }
                  />
                </Form.Item>
              </Col>
              <Col md={3} xs={24}>
                <Form.Item
                  label={
                    <span>
                      Desconto
                      <Tooltip title="Valor Adicional da Compra">
                        <InfoCircleIcon />
                      </Tooltip>
                    </span>
                  }
                  name="discounts"
                >
                  <MonetaryInput2
                    style={{ height: "2.5rem" }}
                    getValue={(value) =>
                      updateFormValue("discountValue", +value)
                    }
                  />
                </Form.Item>
              </Col>
              <Col md={3} xs={24}>
                <Form.Item
                  label={
                    <span>
                      Juros
                      <Tooltip title="Valor dos Juros">
                        <InfoCircleIcon />
                      </Tooltip>
                    </span>
                  }
                  name="interest_amount"
                >
                  <MonetaryInput2
                    style={{ height: "2.5rem" }}
                    getValue={(value) =>
                      updateFormValue("valueInterest", +value)
                    }
                  />
                </Form.Item>
              </Col>
              <Col md={3} xs={24}>
                <Form.Item
                  label={
                    <span>
                      Frete
                      <Tooltip title="Valor do Frete">
                        <InfoCircleIcon />
                      </Tooltip>
                    </span>
                  }
                  name="freight"
                >
                  <MonetaryInput2
                    style={{ height: "2.5rem" }}
                    getValue={(value) =>
                      updateFormValue("valueFreight", +value)
                    }
                  />
                </Form.Item>
              </Col>
              <Col md={ocurrence !== PaymentCondition.A_VISTA ? 3 : 5} xs={24}>
                <Form.Item
                  label={
                    <span>
                      Total da Conta
                      <Tooltip title="Soma total da conta (Valor + Valor Adicional - Desconto + Juros + Frete)">
                        <InfoCircleIcon />
                      </Tooltip>
                    </span>
                  }
                  name="total"
                >
                  <Input
                    style={{ height: "2.5rem" }}
                    readOnly
                    value={formValues.totalAccount}
                    placeholder={`R$ ${currencyFormater(
                      +formValues.totalAccount
                    ).toString()}`}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={8}>
              <Col md={6} xs={24}>
                <Form.Item
                  label={
                    <span>
                      Data de Venc.
                      <Tooltip title="Data de vencimento da conta">
                        <InfoCircleIcon />
                      </Tooltip>
                    </span>
                  }
                  name="due_date"
                  rules={[{ required: true, message: "Campo obrigatório" }]}
                >
                  <DatePicker
                    placeholder="dd/mm/aaaa"
                    format="DD/MM/YYYY"
                    name="due_date"
                  />
                </Form.Item>
              </Col>
              <Col md={6} xs={24}>
                <Form.Item
                  name={`payment_method`}
                  label={
                    <span>
                      Forma de Pgto.
                      <Tooltip title="Método de pagamento">
                        <InfoCircleIcon />
                      </Tooltip>
                    </span>
                  }
                  rules={[{ required: true, message: "Campo obrigatório" }]}
                >
                  <Select
                    placeholder="Selecione uma opção"
                    onChange={(value) => {
                      form.setFieldsValue({ payment_method: value });
                      setSelectedOcurrencyType(+value);
                    }}
                  >
                    {OrderPaymentType?.map((type) => (
                      <Select.Option value={type.value} key={type.value}>
                        {type.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col md={6} xs={24}>
                <Form.Item
                  label={
                    <span>
                      Data de Emissão
                      <Tooltip title="Data que a conta foi oficialmente emitida">
                        <InfoCircleIcon />
                      </Tooltip>
                    </span>
                  }
                  name="created_at"
                  rules={[{ required: true, message: "Campo obrigatório" }]}
                >
                  <DatePicker
                    placeholder="dd/mm/aaaa"
                    format="DD/MM/YYYY"
                    name="created_at"
                  />
                </Form.Item>
              </Col>
              <Col md={6} xs={24}>
                <Form.Item
                  label={
                    <span>
                      Mês de Competência
                      <Tooltip title="Mês e ano da competência">
                        <InfoCircleIcon />
                      </Tooltip>
                    </span>
                  }
                  name="month_competence"
                  rules={[{ required: true, message: "Campo obrigatório" }]}
                >
                  <DatePicker
                    placeholder="mm/aaaa"
                    format="MM/YYYY"
                    picker="month"
                    name="month_competence"
                    locale={locale}
                  />
                </Form.Item>
              </Col>
            </Row>

            {selectedOcurrencyType === 5 && (
              <Row>
                <Col xs={24}>
                  <Form.Item
                    label={
                      <span>
                        Código do Boleto
                        <Tooltip title="Mês e ano da competência">
                          <InfoCircleIcon />
                        </Tooltip>
                      </span>
                    }
                    name="cod_bankslip"
                  >
                    <Input
                      value={form.getFieldValue("cod_bankslip")}
                      placeholder="Digite o código do boleto"
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}

            <Row>
              <Form.Item
                label={
                  <span>
                    Data do Pagamento
                    <Tooltip title="Data do Pagamento">
                      <InfoCircleIcon />
                    </Tooltip>
                  </span>
                }
                name="payment_date"
              >
                <DatePicker
                  placeholder="dd/mm/aaaa"
                  format="DD/MM/YYYY"
                  name="payment_date"
                />
              </Form.Item>
            </Row>

            <Row gutter={8}>
              <Col xs={24}>
                <Title>Observações e Anexos</Title>
              </Col>
            </Row>

            <Row gutter={8}>
              <Col xs={24}>
                <Form.Item label="Observação" name="observation">
                  <Input.TextArea
                    name="observation"
                    placeholder="Adicione uma observação"
                    maxLength={255}
                    style={{ height: "2.5rem" }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col xs={24}>
                <Form.Item label="Anexos">
                  <InputFile
                    id="input_file"
                    type="file"
                    maxLength={3}
                    accept=".pdf, .jpg, .jpeg, .png"
                    style={{ display: "none" }}
                    multiple
                    onChange={(e) => {
                      const files = e.target.files
                        ? Array.from(e.target.files)
                        : [];
                      setSelectedFiles(files);
                    }}
                  />
                  <StyledInput htmlFor="input_file">
                    Escolher arquivos
                  </StyledInput>
                </Form.Item>
              </Col>
              <Col xs={24}>
                {selectedFiles.map((file, index) => (
                  <ContentFileView key={index}>
                    <span>{file.name}</span>
                    <button
                      type="button"
                      onClick={() => {
                        setSelectedFiles((prevFiles) =>
                          prevFiles.filter((_, item) => item !== index)
                        );
                      }}
                    >
                      Deletar
                    </button>
                  </ContentFileView>
                ))}
              </Col>
            </Row>

            <Footer>
              <ButtonSave
                loading={loadingUpload}
                onClick={() => handleSubmit()}
              >
                Salvar
              </ButtonSave>
              <Link
                to={{
                  pathname: "/bills-to-pay",
                  state: { store_param: store },
                }}
              >
                <Button>Cancelar</Button>
              </Link>
            </Footer>
          </Content>
        </Form>
      </Container>
    </PageContainer>
  );
};

export default NewAccountPayable;
