import styled, { css } from "styled-components";

import {
  Modal as ModalAnt,
  Button as ButtonAnt,
  Select as SelectAnt,
  Row as RowAnt,
  Col as ColAnt,
  Input as InputAnt,
  Table as TableAnt,
} from "antd";

export const Modal = styled(ModalAnt)`
  .ant-modal-header {
    padding: 0.5rem 1rem;
  }
  .ant-modal-body {
    padding: 0.5rem 1rem;
  }
  .ant-modal-footer {
    padding: 0.5rem 1rem;
  }

  .ant-form-item {
    margin: 0;
  }
`;

const ButtonCSS = css`
  font-weight: 500;
  font-size: 1rem;
  height: 3rem;
  border-radius: 5px;
`;

export const ButtonCancel = styled.button`
  ${ButtonCSS}
  color: var(--gray-650);
  margin-right: 0.5rem;
  
  :hover {
    background-color: var(--white-100);
  }
`;

export const ButtonSave = styled(ButtonAnt)`
  ${ButtonCSS}
  color: white;
  background-color: var(--orange-350);
  transition: 0.2s;

  :hover,
  :active,
  :focus {
    background-color: var(--orange-600);
    border: 1px solid var(--orange-600);
    color: white;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .ant-table-thead > tr > th {
    padding: 0.2rem;
  }
  .ant-table-tbody > tr > td {
    padding: 0.2rem;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 4rem;
  margin: 0.5rem 0;
  padding: 0.3rem;

  .span-title {
    font-weight: 500;
    color: var(--blue-900);
  }
`;

export const Body = styled.div`
  display: flex;
  width: 100%;
  margin: 0.5rem 0;
`;

export const Footer = styled(RowAnt)`
  display: flex;
  align-items: center;
  width: 100%;
  height: 5rem;
  margin: 0.5rem 0;

  .col-total {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  .span-total {
    weight: 500;
    font-size: 1.2rem;
    color: var(--gray-200);
  }
  .span-total-result {
    weight: 400;
    font-size: 1.2rem;
    color: var(--gray-530);
  }
`;

export const Row = styled(RowAnt)``;

export const Col = styled(ColAnt)``;

export const Select = styled(SelectAnt)`
  width: 100%;
`;

export const Input = styled(InputAnt)`
  width: 100%;
`;

export const ItemList = styled.div``;

export const Table = styled(TableAnt)`
  margin-top: 0.2rem;

  input {
    width: 50%;
    padding: 0 0.5rem;
    border: 1px solid var(--gray-75);
  }
`;

export const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
