import styled from "styled-components";
import { Modal as ModalAnt, Select as SelectAnt, Row } from "antd";

export const RowPartialPaymentModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;

  :first-child {
    margin-bottom: 1rem;
  }
`;

export const RowElement = styled(Row)`
  width: 100%;
`;

export const Select = styled(SelectAnt)`
  width: 100%;
  height: 2.5rem !important;

  .ant-select-selection-item {
    display: flex;
    align-items: center;
  }

  .ant-select-selector {
    height: 2.5rem !important;
  }
`;

export const LabelItem = styled.label`
  color: var(--black);
  font-size: 1rem;
  font-weight: 500;
  text-transform: capitalize;
`;

export const PartialTotal = styled.span`
  color: var(--gray-170);
  font-size: .9rem;
  margin-left: .3rem;
`;

export const ModalPartialPayment = styled(ModalAnt)`
  .ant-modal-body {
    padding: 0.5rem 1rem;
  }

  .ant-modal-header {
    padding: 0.5rem 1rem 0;
  }
`;